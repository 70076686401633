import React, { useState } from "react";
import BotLoginView from "./BotLoginView";
import BotMainView from "./BotMainView";
import BotChatView from "./BotChatView";
import { useQueryClient } from "@tanstack/react-query";

function BotView({
  botName,
  setBotName,
  botColor,
  setBotColor,
  botChatColor,
  setBotChatColor,
  userChatColor,
  setUserChatColor,
  topBannerColor,
  setTopBannerColor,
  fontStyle,
  setFontStyle,
  backgroundColor,
  setBackgroundColor,
  pattern,
  setPattern,
  welcomeMessage,
  setWelcomeMessage,
  handleBotChange,
  bot,
  tempBot,
  buttonColor,
  bubbleColor,
  setBubbleColor,
  botIconContentType,
}) {
  const [selectedTab, setSelectedTab] = useState("login");
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  return (
    <div className="col-4 d-flex flex-column">
      <div className="normal-card">
        <p className="form-label fs-5 fw-semibold">{cachedBranding?.botName}</p>
        <div className="nav nav-tabs nav-fill nav-chat">
          <a
            className={`nav-item chat-link cursor-pointer ${
              "login" === selectedTab ? "active" : ""
            }`}
            onClick={() => setSelectedTab("login")}
          >
            Login View
          </a>
          <a
            className={`nav-item chat-link cursor-pointer ${
              "main" === selectedTab ? "active" : ""
            }`}
            onClick={() => setSelectedTab("main")}
          >
            Main View
          </a>
          <a
            className={`nav-item chat-link cursor-pointer ${
              "chat" === selectedTab ? "active" : ""
            }`}
            onClick={() => setSelectedTab("chat")}
          >
            Chat View
          </a>
        </div>
        <div>
          {"login" === selectedTab ? (
            <BotLoginView
              bot={bot}
              tempBot={tempBot}
              backgroundColor={backgroundColor}
              botColor={botColor}
              buttonColor={buttonColor}
              botIconContentType={botIconContentType}
            />
          ) : "main" === selectedTab ? (
            <BotMainView
              bot={bot}
              tempBot={tempBot}
              backgroundColor={backgroundColor}
              botColor={botColor}
              buttonColor={buttonColor}
              botIconContentType={botIconContentType}
            />
          ) : (
            <BotChatView
              bot={bot}
              tempBot={tempBot}
              backgroundColor={backgroundColor}
              topBannerColor={topBannerColor}
              userChatColor={userChatColor}
              botChatColor={botChatColor}
              welcomeMessage={welcomeMessage}
              botColor={botColor}
              buttonColor={buttonColor}
              bubbleColor={bubbleColor}
              setBubbleColor={setBubbleColor}
              botIconContentType={botIconContentType}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BotView;
