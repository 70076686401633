import React, { useState, useEffect } from "react";
import SearchUser from "./SearchUser";
import GroupNavTab from "./GroupNavTab";
import GroupMemberList from "./GroupMemberList";
import AdminList from "./AdminList";

function GroupDetails({ active, group, refetchGroups }) {
  const [selected, setSelected] = useState("members");
  const [searchText, setSearchText] = useState("");

  const [filteredUsers, setFilteredUsers] = useState(group?.groupMemberUserResponse);

  useEffect(() => {
    if(searchText != ""){
      var filtered = group?.groupMemberUserResponse.filter(c=>c.userName.includes(searchText));
      setFilteredUsers(filtered);
    }else{
      setFilteredUsers(group?.groupMemberUserResponse)
    }

  }, [searchText]);

  return (
    <div
      className={`tab-pane fade ${active ? "show active" : ""}`}
      id="nav-home"
    >
      <div className="details">
        <div className="count-search">
          <SearchUser searchText={searchText} setSearchText={setSearchText} />
          <div className="nav nav-tabs nav-fill">
            <GroupNavTab
              text={`Members (${group?.memberCount})`}
              active={"members" === selected ? true : false}
              handleOnClick={() => setSelected("members")}
            />
            <GroupNavTab
              text={`Admin (${group?.adminCount})`}
              active={"admin" === selected ? true : false}
              handleOnClick={() => setSelected("admin")}
            />
          </div>
        </div>
        <div className="tab-content" id="nav-tabContent">
          <div
            className={`tab-pane fade ${
              "members" === selected ? "show active" : ""
            }`}
          >
            <GroupMemberList
              groupId={group?.groupId}
              members={filteredUsers}
              refetchGroups={refetchGroups}
            />
          </div>
          <div
            className={`tab-pane fade ${
              "admin" === selected ? "show active" : ""
            }`}
          >
            <AdminList admins={group?.groupAdminUserResponse} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupDetails;
