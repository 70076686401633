import moment from "moment/moment";
import React from "react";

function UsageCard({ user, cachedBranding }) {
  console.log("user: ", user);
  return (
    <div className="add-group mt-3 h-100">
      <div className="d-flex justify-content-between align-items-center w-100 border-bottom pb-4 mb-3">
        <h5
          className="fw-semibold border-0 mb-0 p-0"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Usage
        </h5>
      </div>
      <div className="details mt-4">
        <div className="details-name">
          <span>Last Login</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>
            {user?.lastLogin == null
              ? "Never"
              : moment(new Date(user?.lastLogin)).fromNow()}
          </p>
        </div>

        <div className="details-name">
          <span>Responses Sent Token Usage</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>
            {user?.tokenSent} Tokens
          </p>
        </div>

        <div className="details-name">
          <span>Responses Received Token Usage</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>
            {user?.tokenRecieved} Tokens
          </p>
        </div>

        <div className="details-name">
          <span>Average Token Used Daily</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>
            {user?.averageToken} Tokens
          </p>
        </div>

        <div className="details-name">
          <span>Total of Token used since joined</span>
          <p style={{ color: cachedBranding?.page_TextColor }}>
            {user?.tokenRecieved + user?.tokenSent} Tokens
          </p>
        </div>
      </div>
    </div>
  );
}
export default UsageCard;
