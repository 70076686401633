import React, {
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
} from "react";
import GroupCard from "./GroupCard";
import { Pagination } from "components/Custom";
import { useFetchCompanyGroups } from "hooks/groups";

const Groups = forwardRef(({ selected, cachedBranding }, ref) => {
  const [page, setPage] = useState(1);

  const { data: groups, refetch: refetchGroups } = useFetchCompanyGroups({
    pageSize: 8,
    pageNumber: page,
  });

  const itemsPerPage = groups?.data?.pageSize;
  const totalItems = groups?.data?.totalCount;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    refetchGroups({ pageNumber: page });
  }, [page]);

  useImperativeHandle(ref, () => ({
    handleRefetch,
  }));

  useEffect(() => {
    if (groups?.data?.items.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [groups]);

  const handleRefetch = () => {
    refetchGroups({ pageNumber: page });
  };

  useEffect(() => {
    refetchGroups();
  }, []);

  return (
    <div className="col-12">
      <div className="group-list">
        <div className="row">
          {groups?.data?.items &&
            groups?.data?.items.map((group, index) => (
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3" key={index}>
                <GroupCard
                  group={group}
                  refetchGroups={refetchGroups}
                  cachedBranding={cachedBranding}
                />
              </div>
            ))}
        </div>
      </div>
      <Pagination totalPages={totalPages} page={page} setPage={setPage} />
    </div>
  );
});

export default Groups;
