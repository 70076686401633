import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { cleanToken } from "utils";

function DeleteQnaContainerModal({ active, handleClose, containerId }) {
  const navigate = useNavigate();
  const deleteFolder = useMutation({
    mutationFn: () => {
      return axios.delete(API_URL + `/Azure/DeleteContainer/${containerId}`, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      navigate(-1);
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    deleteFolder.mutate();
  };

  return (
    <Modal show={active} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Delete Folder</h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile">
          Are you sure you want to delete this folder?
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn normal-btn"
            onClick={handleClose}
          >
            No
          </button>
          <button
            type="button"
            className="btn delete-btn"
            onClick={handleSubmit}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteQnaContainerModal;
