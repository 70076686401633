import { Sidebar } from "components";
import { BackButton, PageTitle, RoundedButton } from "components/Custom";
import {
  CreateIndexModal,
  MaterialsTable,
  UploadFileModal,
  UploadUrlModal,
} from "components/Materials";
import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Materials } from "components/WalkThrough";
import { API_URL } from "constants";
import axios from "axios";
import toast from "react-hot-toast";
import { cleanToken } from "utils";
import SearchUser from "components/Users/SearchUser";
import {
  useFetchContainerById,
  useFetchMaterialsByContainer,
} from "hooks/azure";
import { DeleteQnaContainerModal } from "components/Qna";

function QnaMaterialFolder() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showUploadUrlModal, setShowUploadUrlModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [showCreateIndexModal, setShowCreateIndexModal] = useState(false);
  const [showMaterialsCard, setShowMaterialsCard] = useState(
    location?.state?.showModal || false
  );
  const { containerId } = useParams();
  const { data: materials, refetch } =
    useFetchMaterialsByContainer(containerId);
  const { data: container, refetch: refetchContainer } =
    useFetchContainerById(containerId);
  const [folderName, setFolderName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [materialData, setMaterialData] = useState(materials?.data);

  useEffect(() => {
    if (searchText != "") {
      var filtered = materials?.data.filter((c) => c.url.includes(searchText));
      setMaterialData(filtered);
    } else {
      setMaterialData(materials?.data);
    }
  }, [searchText]);

  useEffect(() => {
    refetch();
    refetchContainer();
  }, [containerId]);

  useEffect(() => {
    setFolderName(container?.data[0]?.containerName);
  }, [container]);

  useEffect(() => {
    setMaterialData(materials?.data);
  }, [materials]);

  const refresh = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/Azure/RefreshSearchIndexer/${containerId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      toast.success("Refreshed!");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleRefresh = () => {
    refresh.mutate();
  };

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => setShowMaterialsCard(true)} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div
          className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10"
          style={{ padding: "0 5%" }}
        >
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <div className="col-12 d-flex align-items-center">
                  <BackButton />
                  <PageTitle title="Material" time="29 May 2023 - 4:02 PM" />
                </div>
              </div>
            </div>
            <div className="normal-card sub-token">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center w-75">
                  <h6
                    className="me-5 mb-0 w-25"
                    style={{ color: cachedBranding?.page_TextColor }}
                  >
                    {container?.data[0]?.containerName}
                  </h6>
                  <div className="dropdown w-25">
                    <button
                      className="light-btn p-2 rounded mx-3 border-0"
                      type="button"
                      id="dropdownMenu2"
                      onClick={() => setToggleDropDown(!toggleDropDown)}
                    >
                      + Materials
                    </button>
                    <div
                      className={`dropdown-menu ${
                        toggleDropDown ? "show" : ""
                      }`}
                    >
                      <button
                        className="dropdown-item chat-dropdown"
                        type="button"
                        onClick={() =>
                          setShowUploadFileModal(!showUploadFileModal)
                        }
                        style={{ color: cachedBranding?.page_TextColor }}
                      >
                        Files{" "}
                        <img
                          src="/images/files.svg"
                          style={{ width: "25px" }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="search-bar w-50">
                    <img src="/images/search.svg" className="search-ic" />
                    <SearchUser
                      searchText={searchText}
                      setSearchText={setSearchText}
                    />
                  </div>
                </div>
                <div className=" d-flex">
                  {container?.data[0]?.isIndexed ? (
                    <RoundedButton
                      text="Refresh Index"
                      handleOnClick={handleRefresh}
                      className="p-3 ms-3 float-end w-100"
                    />
                  ) : (
                    <RoundedButton
                      text="Create Index"
                      handleOnClick={() => setShowCreateIndexModal(true)}
                      className="p-3 ms-3 float-end w-100"
                    />
                  )}
                  {/* <button
                    className="btn"
                    style={{
                      color: cachedBranding?.page_TextColor,
                      fontSize: "14px",
                    }}
                    onClick={() => setShowRenameFolderModal(true)}
                  >
                    <img className="mx-1" src="/images/edit-user.svg" /> Rename
                  </button> */}
                  <button
                    className="btn"
                    style={{
                      fontSize: "14px",
                      color: cachedBranding?.page_TextColor,
                    }}
                    onClick={() => setShowDeleteFolderModal(true)}
                  >
                    <img className="mx-1" src="/images/delete-user.svg" />{" "}
                    Delete
                  </button>
                </div>
              </div>
              <hr className="mb-0" />
              <MaterialsTable
                files={materialData}
                refetch={refetch}
                cachedBranding={cachedBranding}
                isQna={false}
              />
            </div>
          </div>
        </div>
      </div>
      <CreateIndexModal
        active={showCreateIndexModal}
        handleClose={() => setShowCreateIndexModal(false)}
        refetchContainer={refetchContainer}
        containerId={containerId}
      />
      <UploadFileModal
        active={showUploadFileModal}
        handleClose={() => setShowUploadFileModal(false)}
        refetch={refetch}
        folderId={containerId}
        cachedBranding={cachedBranding}
      />
      <UploadUrlModal
        active={showUploadUrlModal}
        handleClose={() => setShowUploadUrlModal(false)}
        refetch={refetch}
        folderId={containerId}
        folderName={folderName}
        cachedBranding={cachedBranding}
      />
      <DeleteQnaContainerModal
        active={showDeleteFolderModal}
        handleClose={() => setShowDeleteFolderModal(false)}
        containerId={container?.data[0]?.containerId}
      />
      <Materials
        show={showMaterialsCard}
        handleClose={() => setShowMaterialsCard(false)}
        handleSubmit={() =>
          navigate("/settings/script", { state: { showModal: true } })
        }
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default QnaMaterialFolder;
