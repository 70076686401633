import { Sidebar } from "components";
import {
  PageTitle,
  TabToggleButton,
  FilterDropdown,
  DateModal,
} from "components/Custom";
import { StatCard, DataCard, RatingsCard } from "components/Dashboard";
import { Welcome } from "components/WalkThrough";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import {
  useFetchDashboardNumbers,
  useLongestResponseSent,
  useLongestResponseReceived,
  useFetchDashboardNumbersByGroup,
  useLongestResponseSentGroup,
  useLongestResponseReceivedGroup,
  useConsumption,
  useGroupConsumption,
} from "hooks/Dashboard";

function Dashboard() {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [showWelcome, setShowWelcome] = useState(false);
  const [days, setDays] = useState(null);

  const [dateFilterString, setDateFilterString] = useState(" and 1 = 1");
  const [showDateModal, setShowDateModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selected, setSelected] = useState("Users");
  const [longestSent, setLongestSent] = useState([]);
  const [longestReceived, setLongestReceived] = useState([]);
  const [consumption, setConsumption] = useState([]);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const { data: consumptionData, refetch: refetchConsumptionData } =
    useConsumption(dateFilterString);

  const { data: groupConsumptionData, refetch: refetchGroupConsumptionData } =
    useGroupConsumption(dateFilterString);

  const { data: dashboardNumbers, refetch: refetchDasboardNumber } =
    useFetchDashboardNumbers(dateFilterString);

  const { data: groupNumbers, refetch: refetchGroupNumbers } =
    useFetchDashboardNumbersByGroup(dateFilterString);

  const { data: longestResponseSent, refetch: refetchResponseSent } =
    useLongestResponseSent(dateFilterString);

  const {
    data: longestResponsesSentGroup,
    refetch: refetchResponsesSentGroup,
  } = useLongestResponseSentGroup(dateFilterString);

  const { data: longestResponseReceived } =
    useLongestResponseReceived(dateFilterString);

  const { data: longestResponseReceivedGroup } =
    useLongestResponseReceivedGroup(dateFilterString);

  useEffect(() => {
    refetchDasboardNumber();
    refetchConsumptionData();
    refetchGroupConsumptionData();
    refetchGroupNumbers();
    refetchResponseSent();
    refetchResponsesSentGroup();
  }, [dateFilterString]);

  useEffect(() => {
    if ("Users" === selected) {
      setConsumption(consumptionData);
    } else {
      setConsumption(groupConsumptionData);
    }
  }, [consumptionData, groupConsumptionData, selected]);

  useEffect(() => {
    if ("Users" === selected) {
      setData(dashboardNumbers?.data[0]);
    } else {
      setData(groupNumbers?.data[0]);
    }
  }, [selected, dashboardNumbers, groupNumbers]);

  useEffect(() => {
    if ("Users" === selected) {
      setLongestSent(longestResponseSent?.data);
    } else {
      setLongestSent(longestResponsesSentGroup?.data);
    }
  }, [selected, longestResponseSent, longestResponsesSentGroup]);

  useEffect(() => {
    if ("Users" === selected) {
      setLongestReceived(longestResponseReceived?.data);
    } else {
      setLongestReceived(longestResponseReceivedGroup?.data);
    }
  }, [selected, longestResponseReceived, longestResponseReceivedGroup]);

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: cachedBranding?.page_BackgroundColor }}
    >
      <div className="row flex-nowrap">
        <Sidebar
          handleTourClick={() => (setShowWelcome(true), window.scrollTo(0, 0))}
        />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10 px-sm-5">
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <PageTitle title="Dashboard" time="29 May 2023 - 4:02 PM" />
                <div className="col-5 col-lg-5 col-xl-4 filter">
                  <TabToggleButton
                    items={["Users", "Groups"]}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <FilterDropdown
                    days={days}
                    dateFilterString={dateFilterString}
                    setDateFilterString={setDateFilterString}
                    handleSetDays={setDays}
                    handleOpenCustomDates={() => setShowDateModal(true)}
                  />
                </div>
              </div>
            </div>
            <div className="data-summary">
              <div className="row w-100">
                <StatCard
                  title="Token Balance"
                  image="/images/token-ico.svg"
                  total={data?.tokenBalance}
                />
                <StatCard
                  title={`Total ${"Users" === selected ? "Users" : "Groups"}`}
                  image="/images/total-users-ico.svg"
                  total={data?.userCount}
                />
                <StatCard
                  title="Responses Sent Token Usage"
                  image="/images/sent-ico.svg"
                  total={data?.userResponceToken / 4}
                />
                <StatCard
                  title="Responses Received Token Usage"
                  image="/images/received-ico.svg"
                  total={data?.botResponceToken / 4}
                />
              </div>
            </div>
            <div className="data-charts">
              <div className="row w-100">
                <DataCard
                  title="Consumption"
                  type="chart"
                  chart="/images/area-chart.png"
                  consumption={consumption}
                  setConsumption={setConsumption}
                  dateFilterString={dateFilterString}
                />
                <DataCard
                  title="Topics / Categories"
                  selected={selected}
                  type="pie"
                  chart="/images/pie-chart.png"
                  style={{ padding: "0 60px" }}
                  dateFilterString={dateFilterString}
                />
              </div>
            </div>
            <div className="data-tables">
              <div className="row w-100">
                <DataCard
                  title="Longest Responses Sent"
                  type="table"
                  responses={longestSent}
                  dateFilterString={dateFilterString}
                />
                <DataCard
                  title="Longest Responses Received"
                  type="table"
                  responses={longestReceived}
                  dateFilterString={dateFilterString}
                />
              </div>
            </div>
            <RatingsCard
              cachedBranding={cachedBranding}
              selected={selected}
              dateFilterString={dateFilterString}
            />
          </div>
        </div>
      </div>
      <Welcome
        show={showWelcome}
        handleClose={() => setShowWelcome(false)}
        handleSubmit={() =>
          navigate("/company-profile", { state: { showModal: true } })
        }
        cachedBranding={cachedBranding}
      />
      <DateModal
        show={showDateModal}
        handleClose={() => setShowDateModal(false)}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        cachedBranding={cachedBranding}
        setDateFilterString={setDateFilterString}
      />
    </div>
  );
}

export default Dashboard;
