import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import { useFetchSubscription } from "hooks/Subscription";
import { RoundedButton } from "components/Custom"; // Import RoundedButton from the correct path
import toast from "react-hot-toast";

function TokenBalanceCard({ cachedBranding }) {
  const {
    data: subscription,
    refetch: refetchToken,
    isFetching,
  } = useFetchSubscription();

  const [tokenAmount, setTokenAmount] = useState(0);
  const [isLimited, setIsLimited] = useState(false);

  useEffect(() => {
    if (subscription) {
      // Update state values if subscription data is available
      setTokenAmount(subscription?.data?.companySubscription?.tokenRestriction || 0);
      setIsLimited(subscription?.data?.companySubscription?.isLimited || false);
    }
  }, [subscription]);

  const saveToken = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL +
          `/Subscription/EditTokenRestriction/${tokenAmount}/${isLimited}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: () => {
      refetchToken();
      toast.success("Saved Successfully");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSaveChatSettings = () => {
    saveToken.mutate();
  };

  return (
    <div className="normal-card tok-restrict">
      <div className="d-flex flex-column mb-3">
        <span style={{ color: cachedBranding?.page_TextColor }}>
          Token Balance
        </span>
        <div className="total-tokens">
          <div className="result">
            <img src="/images/token-ico.svg" alt="Token Icon" />
            <h3
              className="mb-0 fw-bold"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              {subscription?.data?.companySubscription?.tokenBalance}
            </h3>
          </div>
        </div>
      </div>
      <h5
        className="fw-semibold mb-2"
        style={{ color: cachedBranding?.page_TextColor }}
      >
        Set Token Restriction
      </h5>
      <div className="d-flex flex-row justify-content-between">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            checked={isLimited}
            onChange={() => setIsLimited(true)}
          />
          <label
            className="form-check-label fs-6 fw-medium"
            htmlFor="flexRadioDefault1"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Limited
          </label>
        </div>
        <div className="form-check w-50">
          <input
            className="form-check-input"
            type="radio"
            checked={!isLimited}
            onChange={() => setIsLimited(false)}
            id="flexRadioDefault2"
          />
          <label
            className="form-check-label fs-6 fw-medium"
            htmlFor="flexRadioDefault2"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Unlimited
          </label>
        </div>
      </div>

      <div className="w-50">
        <h6
          className="mb-2 fw-medium mt-3"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Set Token Amount
        </h6>
        <input
          type="number"
          min="0"
          max="1000000"
          maxLength="9"
          className="num-inp w-100"
          value={tokenAmount}
          onChange={(e) => setTokenAmount(e.target.value)}
        />
        <RoundedButton
          text="Save"
          className="btn main-btn mb-3 w-100"
          handleOnClick={handleSaveChatSettings}
        />
      </div>
      <span className="" style={{ color: cachedBranding?.page_TextColor }}>
        Monitor your token usage and set your own internal limits on how many
        tokens can be used to avoid exceeding your budget or running out of
        tokens.
      </span>
      <div className="mt-3">
        <img src="/images/warning.svg" alt="Warning Icon" />{" "}
        {/* Added alt attribute */}
        <span style={{ color: cachedBranding?.page_TextColor }}>
          Warning: Once the token reaches 0, all users will be blocked from
          using the chatbot.
        </span>
      </div>
    </div>
  );
}

export default TokenBalanceCard;
