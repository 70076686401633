import React, { useState } from "react";

function ColorInput({ className, label, value, onChange }) {
  const [hex, setHex] = useState(value);

  return (
    <div className={`custom-color ${className}`}>
      <label className="form-label mb-2 fw-medium">{label}</label>
      <div className="color-pick">
        <input
          type="color"
          className="border border-0 rounded-circle form-control w-25 form-control-color h-30"
          value={value}
          onChange={(e) => (onChange(e.target.value), setHex(e.target.value))}
        />
        <input
          type="text"
          className="border border-0 w-75"
          style={{ fontSize: "12px" }}
          value={hex}
          onChange={(e) => (onChange(e.target.value), setHex(e.target.value))}
        />
      </div>
    </div>
  );
}

export default ColorInput;
