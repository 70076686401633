import React, { useState, useEffect } from "react";
import UserItem from "./UserItem";
import AddUserModal from "./AddUserModal";
import UploadCsvGroupUsers from "./UploadCsvGroupUsers";
import { useMutation } from "@tanstack/react-query";
import { cleanToken } from "utils";
import axios from "axios";
import { API_URL } from "constants";
import { useExportUsersInGroup, useFetchUsersNotInGroup } from "hooks/groups";
import toast from "react-hot-toast";
import SearchUser from "./SearchUser";
import { isEmpty } from "lodash";

function EditUsersCard({ group, refetchGroup, cachedBranding }) {
  const [searchText, setSearchText] = useState("");
  const [toggled, setToggled] = useState("members");
  const [showCsv, setShowCsv] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const exportUsersInGroupQuery = useExportUsersInGroup(group?.groupId);
  const { data: usersNotMembers, refetch: refetchMembers } =
    useFetchUsersNotInGroup(group?.groupId);

  const [filteredUsers, setFilteredUsers] = useState(
    group?.groupMemberUserResponse
  );

  console.log("filteredUsers: ", filteredUsers);

  useEffect(() => {
    if (searchText != "") {
      var filtered = group?.groupMemberUserResponse.filter((c) =>
        c.userName.includes(searchText)
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(group?.groupMemberUserResponse);
    }
  }, [searchText]);

  useEffect(() => {
    setFilteredUsers(group?.groupMemberUserResponse);
  }, []);

  useEffect(() => {
    if (!isEmpty(group?.groupId)) {
      refetchMembers();
    }
    setFilteredUsers(group?.groupMemberUserResponse);
  }, [group]);

  const handleDownload = async () => {
    try {
      const data = await exportUsersInGroupQuery.mutateAsync();
      const url = window.URL.createObjectURL(data.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "users.xlsx";
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success("File successfully installed");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const deleteUserFromGroup = useMutation({
    mutationFn: (userId) => {
      return axios.post(
        API_URL + `/Group/RemoveUserFromGroup/${group?.groupId}/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: () => {
      refetchGroup();
      refetchMembers();
      toast.success("User successfully deleted");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleDelete = (userId) => {
    deleteUserFromGroup.mutate(userId);
  };
  return (
    <div className="add-members">
      <div className="d-flex justify-content-between mb-3 border-bottom">
        <h5
          className="fw-semibold pb-2"
          style={{ color: cachedBranding?.page_TextColor }}
        >
          Edit Users
        </h5>
        <div className="">
          <label
            className="custom-file-upload mx-3"
            onClick={() => setShowAddUserModal(true)}
          >
            Add
          </label>
          <label className="custom-file-upload mx-3" onClick={handleDownload}>
            Export
          </label>
          <label
            className="custom-file-upload"
            onClick={() => setShowCsv(true)}
          >
            Upload CSV
          </label>
        </div>
      </div>
      <div className="search-bar">
        <img src="/images/search.svg" className="search-ic" />
        <SearchUser searchText={searchText} setSearchText={setSearchText} />
      </div>
      <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
        <a
          className={`nav-item cursor-pointer nav-link`}
          onClick={() => setToggled("members")}
          style={{
            borderBottomColor:
              "members" === toggled ? cachedBranding?.page_ButtonColor : "",
            borderBottomWidth: "members" === toggled ? "3px" : "",
            color:
              "members" === toggled ? cachedBranding?.page_ButtonColor : "",
          }}
        >
          Members ({group?.memberCount})
        </a>
        <a
          className={`nav-item nav-link cursor-pointer`}
          id="nav-profile-tab"
          onClick={() => setToggled("admin")}
          style={{
            borderBottomColor:
              "admin" === toggled ? cachedBranding?.page_ButtonColor : "",
            borderBottomWidth: "admin" === toggled ? "3px" : "",
            color: "admin" === toggled ? cachedBranding?.page_ButtonColor : "",
          }}
        >
          Admin ({group?.adminCount})
        </a>
      </div>

      <div className="tab-content" id="nav-tabContent">
        <div
          className={`tab-pane fade ${
            "members" === toggled ? "show active" : ""
          }`}
          id="nav-member"
          role="tabpanel"
        >
          <div className="group-members-list">
            {filteredUsers &&
              filteredUsers.map((member, index) => (
                <UserItem
                  type="user"
                  key={index}
                  user={member}
                  handleDelete={handleDelete}
                  cachedBranding={cachedBranding}
                />
              ))}
          </div>
        </div>

        <div
          className={`tab-pane fade ${
            "admin" === toggled ? "show active" : ""
          }`}
        >
          <div className="group-members-list">
            {group?.groupAdminUserResponse &&
              group?.groupAdminUserResponse.map((admin, index) => (
                <UserItem
                  type="temporaryAdmin"
                  key={index}
                  user={admin}
                  handleDelete={handleDelete}
                  cachedBranding={cachedBranding}
                />
              ))}
          </div>
        </div>
      </div>
      <UploadCsvGroupUsers
        show={showCsv}
        handleClose={() => setShowCsv(false)}
        refetchGroup={refetchGroup}
        group={group}
      />
      <AddUserModal
        show={showAddUserModal}
        handleClose={() => setShowAddUserModal(false)}
        group={group}
        refetchGroup={refetchGroup}
        usersNotMembers={usersNotMembers}
        refetchMembers={refetchMembers}
      />
    </div>
  );
}

export default EditUsersCard;
