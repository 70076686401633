import React from "react";
import { useQueryClient } from "@tanstack/react-query";

function PageTitle({ title, time, className = "" }) {
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;

  return (
    <div className={`col-7 col-lg-8 col-xl-7 ${className}`}>
      <div className="main-title">
        <h3 style={{ color: cachedBranding?.page_TextColor }}>{title}</h3>
      </div>
    </div>
  );
}

export default PageTitle;
