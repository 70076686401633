import React, { useRef, useState } from "react";
import ColorInput from "./ColorInput";

function MainPageSettings({
  sidebarPrimaryColor,
  setSidebarPrimaryColor,
  sidebarSecondaryColor,
  setSidebarSecondaryColor,
  sidebarTextColor,
  setSidebarTextColor,
  pageBackgroundColor,
  setPageBackgroundColor,
  pageButtonColor,
  setPageButtonColor,
  pageFontStyle,
  setPageFontStyle,
  pageTextColor,
  setPageTextColor,
  handleImageChange,
  selectedButtonColor,
  setSelectedButtonColor,
}) {
  const fileInputRef = useRef(null);

  const handleSelectImage = () => {
    // Trigger the file input using the ref
    fileInputRef.current.click();
  };

  return (
    <div className="row w-100">
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="normal-card">
          <div>
            <div className="mb-2 mt-3">
              <label className="form-label fs-5 fw-semibold">Sidebar</label>
            </div>
            <div className="d-flex w-100 mb-4">
              <div className="custom-color w-25 mt-4">
                <img
                  src="/images/upload-ico.svg"
                  onClick={handleSelectImage}
                  className="cursor-pointer"
                />
                <label className="form-label mb-2 fw-semibold ms-3">
                  Upload Logo
                </label>
                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </div>
              <ColorInput
                label="Primary Color"
                className="px-2 mx-1 w-25"
                value={sidebarPrimaryColor}
                onChange={setSidebarPrimaryColor}
              />
              <ColorInput
                label="Secondary Color"
                className="px-2 mx-1 w-25"
                value={sidebarSecondaryColor}
                onChange={setSidebarSecondaryColor}
              />
              <ColorInput
                label="Text Color"
                className="px-2 mx-1 w-25"
                value={sidebarTextColor}
                onChange={setSidebarTextColor}
              />
            </div>
          </div>
          <div>
            <div className="mb-2 mt-3">
              <label className="form-label fs-5 fw-semibold">Page</label>
            </div>
            <div className="d-flex w-100 mb-4">
              <div className="custom-color w-25">
                <label className="form-label mb-2 fw-medium">Font Style</label>
                <div className="color-pick">
                  <select
                    className="form-select border border-0"
                    style={{ fontSize: "12px" }}
                    value={pageFontStyle || ""} // Set value to pageFontStyle, or an empty string if it's empty
                    onChange={(e) => setPageFontStyle(e.target.value)}
                  >
                    <option value="">Select a font</option>
                    <option value={"Montserrat"}>Montserrat</option>
                    <option value={"Poppins"}>Poppins</option>
                    <option value={"Roboto"}>Roboto</option>
                    <option value={"Raleway"}>Raleway</option>
                  </select>
                </div>
              </div>
              <ColorInput
                label="Background Color"
                className="px-2 mx-1 w-25"
                value={pageBackgroundColor}
                onChange={setPageBackgroundColor}
              />
              <ColorInput
                label="Text Color"
                className="px-2 mx-1 w-25"
                value={pageTextColor}
                onChange={setPageTextColor}
              />
              <ColorInput
                label="Button Color"
                className="px-2 mx-1 w-25"
                value={pageButtonColor}
                onChange={setPageButtonColor}
              />
            </div>
            <div className="d-flex w-100 mb-4">
              <ColorInput
                label="Selected Button Color"
                className="px-2 mx-1 w-25"
                value={selectedButtonColor}
                onChange={setSelectedButtonColor}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPageSettings;
