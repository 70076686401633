import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Sidebar } from "components";
import { CompanyDetails } from "components/WalkThrough";
import { API_URL } from "constants";
import { useFetchCompanyDescription } from "hooks/company";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { cleanToken } from "utils";

function CompanyProfile() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [showCompanyDetails, setShowCompanyDetails] = useState(
    location?.state?.showModal || false
  );
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const { data: companyDescription, refetch } = useFetchCompanyDescription();

  useEffect(() => {
    if (!isEmpty(companyDescription?.data)) {
      setDescription(companyDescription?.data[0]?.description);
    }
  }, [companyDescription]);

  const editCompanyInfo = useMutation({
    mutationFn: (data) => {
      return axios.post(API_URL + `/Company/EditCompanyInfo`, data, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      refetch();
      toast.success("Updated successfully")
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    editCompanyInfo.mutate({
      description: description,
    });
  };

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: cachedBranding?.page_BackgroundColor,
        minHeight: "100vh",
      }}
    >
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => setShowCompanyDetails(true)} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div
          className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10 px-sm-5"
          style={{ padding: "0 200px" }}
        >
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <div className="col-12 d-flex align-items-center">
                  <div className="main-title">
                    <h3 style={{ color: cachedBranding?.page_TextColor }}>
                      Company Profile
                    </h3>
                  </div>
                </div>
                <div className="col-12">
                  <div className="add-group mt-3">
                    <div className="d-flex justify-content-between align-items-center w-100 pb-3">
                      <h5
                        className="fw-semibold border-0 mb-0 p-0"
                        style={{ color: cachedBranding?.page_TextColor }}
                      >
                        {companyDescription?.data[0]?.name}
                      </h5>
                      {!editMode ? (
                        <a
                          className="cursor-pointer"
                          onClick={() => setEditMode(true)}
                        >
                          <img src="/images/edit-user.svg" />
                        </a>
                      ) : (
                        <button
                          className="btn main-btn"
                          onClick={() => (handleSubmit(), setEditMode(false))}
                          style={{
                            backgroundColor: cachedBranding?.page_ButtonColor,
                          }}
                        >
                          Save
                        </button>
                      )}
                    </div>
                    <div className="details d-flex w-100 flex-wrap">
                      <div
                        className={`details-name d-flex flex-column mb-3 ${
                          editMode && "w-100"
                        }`}
                      >
                        {!editMode ? (
                          <span
                            className="fw-normal mt-1"
                            style={{ color: cachedBranding?.page_TextColor }}
                          >
                            {description}
                          </span>
                        ) : (
                          <textarea
                            className="form-control mt-1"
                            rows="12"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ color: cachedBranding?.page_TextColor }}
                          >
                            {description}
                          </textarea>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CompanyDetails
        show={showCompanyDetails}
        handleClose={() => setShowCompanyDetails(false)}
        handleSubmit={() => navigate("/users", { state: { showModal: true } })}
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default CompanyProfile;
