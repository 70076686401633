import React, { useState } from "react";
import InstituteCard from "./InstituteCard";
import NewChatButton from "./NewChatButton";

function InstituteList({
  active,
  handleSelect,
  chats,
  refetchIndividualChats,
  refetchFavorites,
  cachedBranding,
  setSelectedChat,
  refetchInstituteChats,
  refetchInstituteChatsGroup,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const buttonStyle = {
    backgroundColor: isHovered ? cachedBranding?.page_ButtonColor : "#fff",
    borderColor: cachedBranding?.page_ButtonColor,
    color: isHovered ? "#fff" : cachedBranding?.page_ButtonColor,
    fontSize: "15px",
  };

  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <NewChatButton
        cachedBranding={cachedBranding}
        handleSelect={handleSelect}
        isGroup={false}
        setShowNewGroupChat={() => {}}
      />
      <div className="test">
        {chats &&
          chats.map(
            (message, index) =>
              !message?.isInternet && (
                <InstituteCard
                  handleSelect={handleSelect}
                  message={message}
                  key={index}
                  refetchIndividualChats={refetchIndividualChats}
                  refetchFavorites={refetchFavorites}
                  cachedBranding={cachedBranding}
                  setSelectedChat={setSelectedChat}
                  refetchInstituteChats={refetchInstituteChats}
                  refetchInstituteChatsGroup={refetchInstituteChatsGroup}
                />
              )
          )}
      </div>
    </div>
  );
}

export default InstituteList;
