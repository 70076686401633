import { useQueryClient } from "@tanstack/react-query";
import { Sidebar } from "components";
import { Chats, Favorites } from "components/ChatConsole";
import ChatBox from "components/ChatConsole/ChatBox";
import { Chat } from "components/WalkThrough";
import { useFetchCompanyBranding } from "hooks/branding";
import {
  useFetchChatById,
  useFetchFavoriteChats,
  useFetchGroupChats,
  useFetchGroupInstituteChats,
  useFetchGroupMaterialChats,
  useFetchIndividualChats,
  useFetchIndividualInstituteChats,
  useFetchIndividualMaterialChats,
} from "hooks/chat";
import { useFetchChatSettings } from "hooks/company";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

function ChatConsole() {
  const navigate = useNavigate();
  const location = useLocation();
  const [chatType, setChatType] = useState("new");
  const [selectedChat, setSelectedChat] = useState("");
  const [group, setGroup] = useState("");
  const [groupInit, setGroupInit] = useState("");
  const [newGroup, setNewGroup] = useState("");
  const [chatFetched, setChatFetched] = useState([]);
  const [isInstitute, setIsInstitute] = useState(false);
  const [isQna, setIsQna] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedTab, setSelectedTab] = useState("individual");
  const [showChatModal, setShowChatModal] = useState(
    location?.state?.showModal || false
  );
  const { data: branding, refetch: refetchBranding } =
    useFetchCompanyBranding();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const { data: chat, refetch: refetchChat } = useFetchChatById(selectedChat);
  const { data: individualChats, refetch: refetchIndividualChats } =
    useFetchIndividualChats(searchText);
  const { data: favoriteChats, refetch: refetchFavorites } =
    useFetchFavoriteChats();
  const { data: groups, refetch: refetchGroups } =
    useFetchGroupChats(searchText);
  const { data: chatSettings, refetch: refetchSettings } =
    useFetchChatSettings();
  const { data: instituteChats, refetch: refetchInstituteChats } =
    useFetchIndividualInstituteChats(
      chatSettings?.data[0]?.activeIndexerId,
      searchText
    );
  const { data: instituteChatsGroup, refetch: refetchInstituteChatsGroup } =
    useFetchGroupInstituteChats(
      chatSettings?.data[0]?.activeIndexerId,
      searchText
    );
  const { data: materialChatsGroup, refetch: refetchMaterialChatsGroup } =
    useFetchGroupMaterialChats(
      chatSettings?.data[0]?.activeProjectId,
      searchText
    );
  const { data: materialChats, refetch: refetchMaterialChats } =
    useFetchIndividualMaterialChats(
      chatSettings?.data[0]?.activeProjectId,
      searchText
    );

  // console.log("instituteChats: ", instituteChats?.data);
  // console.log("instituteChatsGroup: ", instituteChatsGroup?.data);

  useEffect(() => {
    if (selectedChat && "new" !== chatType) {
      refetchChat(selectedChat);
      refreshLeftChatBar();
    } else {
      setChatFetched([]);
      refreshLeftChatBar();
    }
  }, [selectedChat]);

  // console.log("instituteChats: ", instituteChats);
  // console.log("chatSettings: ", chatSettings);

  const refreshLeftChatBar = () => {
    refetchIndividualChats();
    refetchGroups();
    if (!isEmpty(chatSettings?.data[0]?.activeProjectId)) {
      refetchInstituteChats();
      refetchMaterialChats();
      refetchInstituteChatsGroup();
      refetchMaterialChatsGroup();
    }
  };

  useEffect(() => {
    refreshLeftChatBar();
  }, []);

  useEffect(() => {
    refreshLeftChatBar();
  }, [chatSettings, searchText]);

  useEffect(() => {
    if (!isEmpty(chat)) {
      setChatFetched(chat);
    }
  }, [chat]);

  return (
    <div
      className="container-fluid chat-container"
      style={{ backgroundColor: cachedBranding?.page_BackgroundColor }}
    >
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={() => setShowChatModal(true)} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10 px-sm-5">
          <div className="row area chat-area">
            <div className="col-5 col-md-5 col-lg-4 col-xl-3 normal-card chat-console">
              <div className="page-title">
                <div className="main-title">
                  <h3
                    className="chat-console-title"
                    style={{
                      color: cachedBranding?.page_TextColor,
                    }}
                  >
                    Chat Console
                  </h3>
                </div>
              </div>
              <div className="search-bar position-relative">
                <img src="/images/search.svg" className="search-ic" />
                <input
                  className="search-inp"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
              </div>
              <Favorites
                handleSelect={(chatId, type) => (
                  setChatType(type), setSelectedChat(chatId)
                )}
                favoriteChats={favoriteChats}
                refetchFavorites={refetchFavorites}
                refetchIndividualChats={refetchIndividualChats}
                cachedBranding={cachedBranding}
                setSelectedChat={setSelectedChat}
                refetchInstituteChatsGroup={refetchInstituteChatsGroup}
                refetchInstituteChats={refetchInstituteChats}
              />
              <Chats
                handleSelect={(chatId, type, groupId = "", groupInit = "") => (
                  setChatType(type),
                  setSelectedChat(chatId),
                  setGroup(groupId),
                  setGroupInit(groupInit)
                )}
                chatsTotal={10}
                individualChats={individualChats?.data}
                refetchIndividualChats={refetchIndividualChats}
                groups={groups?.data}
                refetchGroups={refetchGroups}
                refetchFavorites={refetchFavorites}
                setNewGroup={setNewGroup}
                cachedBranding={cachedBranding}
                setIsInstitute={setIsInstitute}
                isInstitute={isInstitute}
                instituteChats={instituteChats}
                instituteChatsGroup={instituteChatsGroup}
                setSelectedChat={setSelectedChat}
                refetchInstituteChatsGroup={refetchInstituteChatsGroup}
                refetchInstituteChats={refetchInstituteChats}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setChatType={setChatType}
                materialChats={materialChats}
                refetchMaterialChats={refetchMaterialChats}
                isQna={isQna}
                setIsQna={setIsQna}
                materialChatsGroup={materialChatsGroup}
                refetchMaterialChatsGroup={refetchMaterialChatsGroup}
              />
            </div>
            <ChatBox
              handleSelect={(chatId, type, groupId = "", groupInit = "") => (
                setChatType(type),
                setSelectedChat(chatId),
                setGroup(groupId),
                setGroupInit(groupInit)
              )}
              chatType={chatType}
              chat={!isEmpty(selectedChat) ? chatFetched : {}}
              setChatType={setChatType}
              setSelectedChat={setSelectedChat}
              selectedChat={selectedChat}
              refetchChat={refetchChat}
              group={group}
              groupInit={groupInit}
              newGroup={newGroup}
              chatSettings={chatSettings?.data[0]}
              isInstitute={isInstitute}
              isQna={isQna}
              cachedBranding={cachedBranding}
              refetchFavorites={refetchFavorites}
              refetchIndividualChats={refetchIndividualChats}
              refetchInstituteChats={refetchInstituteChats}
              refetchInstituteChatsGroup={refetchInstituteChatsGroup}
              refetchMaterialChatsGroup={refetchMaterialChatsGroup}
              refetchGroups={refetchGroups}
              selectedTab={selectedTab}
            />
          </div>
        </div>
      </div>
      <Chat
        show={showChatModal}
        setSelectedChat={setSelectedChat}
        handleClose={() => setShowChatModal(false)}
        handleSubmit={() =>
          navigate("/settings/token-restrictions", {
            state: { showModal: true },
          })
        }
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default ChatConsole;
