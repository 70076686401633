import React, { useEffect, useState } from "react";
import InstituteList from "./InstituteList";
import InternetList from "./InternetList";
import { isEmpty } from "lodash";
import QnaList from "./QnaList";

function Individual({
  active,
  handleSelect,
  individualChats,
  refetchIndividualChats,
  refetchFavorites,
  cachedBranding,
  isInstitute,
  setIsInstitute,
  instituteChats,
  setSelectedChat,
  refetchInstituteChats,
  refetchInstituteChatsGroup,
  materialChats,
  refetchMaterialChats,
  isQna,
  setIsQna,
}) {
  const [selected, setSelected] = useState("institute");

  useEffect(() => {
    setSelected("internet");
    setIsInstitute(false);
  }, [active]);

  return (
    <div
      className={`tab-pane fade ${active ? "show active" : ""}`}
      id="nav-individual"
      role="tabpanel"
    >
      <div
        className="nav nav-tabs nav-fill data-filter px-1"
        style={{ height: "unset" }}
      >
        <a
          className={`nav-item chat-data-link data-link cursor-pointer`}
          onClick={() => (
            setSelected("internet"), setIsInstitute(false), setIsQna(false)
          )}
          style={{
            backgroundColor:
              "internet" === selected &&
              !isEmpty(cachedBranding?.page_ButtonColor)
                ? cachedBranding?.page_ButtonColor
                : "internet" === selected &&
                  isEmpty(cachedBranding?.page_ButtonColor)
                ? "#0844e1"
                : "",
            color: "internet" === selected ? "#fff" : "",
          }}
        >
          Internet Data
        </a>
        <a
          className={`nav-item chat-data-link data-link cursor-pointer`}
          onClick={() => (
            setSelected("institute"),
            handleSelect(null, "new"),
            setIsInstitute(true),
            setIsQna(false)
          )}
          style={{
            backgroundColor:
              "institute" === selected &&
              !isEmpty(cachedBranding?.page_ButtonColor)
                ? cachedBranding?.page_ButtonColor
                : "institute" === selected &&
                  isEmpty(cachedBranding?.page_ButtonColor)
                ? "#0844e1"
                : "",
            color: "institute" === selected ? "#fff" : "",
          }}
        >
          Institute Data
        </a>
        <a
          className={`nav-item chat-data-link data-link cursor-pointer`}
          onClick={() => (
            setSelected("qna"),
            handleSelect(null, "new"),
            setIsInstitute(false),
            setIsQna(true)
          )}
          style={{
            backgroundColor:
              "qna" === selected && !isEmpty(cachedBranding?.page_ButtonColor)
                ? cachedBranding?.page_ButtonColor
                : "qna" === selected &&
                  isEmpty(cachedBranding?.page_ButtonColor)
                ? "#0844e1"
                : "",
            color: "qna" === selected ? "#fff" : "",
          }}
        >
          Custom QnA
        </a>
      </div>
      <div className="chat-data">
        <div className="tab-content" id="nav-tabData">
          {/** @TODO should flip */}
          {selected === "institute" ? (
            <InternetList
              active={"institute" === selected ? true : false}
              handleSelect={handleSelect}
              chats={individualChats}
              refetchIndividualChats={refetchIndividualChats}
              refetchFavorites={refetchFavorites}
              instituteChats={instituteChats}
              cachedBranding={cachedBranding}
              setSelectedChat={setSelectedChat}
              refetchInstituteChats={refetchInstituteChats}
              refetchInstituteChatsGroup={refetchInstituteChatsGroup}
              materialChats={materialChats}
              refetchMaterialChats={refetchMaterialChats}
            />
          ) : selected === "qna" ? (
            <QnaList
              active={"qna" === selected ? true : false}
              handleSelect={handleSelect}
              chats={individualChats}
              refetchIndividualChats={refetchIndividualChats}
              refetchFavorites={refetchFavorites}
              instituteChats={instituteChats}
              cachedBranding={cachedBranding}
              setSelectedChat={setSelectedChat}
              refetchInstituteChats={refetchInstituteChats}
              refetchInstituteChatsGroup={refetchInstituteChatsGroup}
              materialChats={materialChats}
              refetchMaterialChats={refetchMaterialChats}
            />
          ) : (
            <InstituteList
              active={"internet" === selected ? true : false}
              handleSelect={handleSelect}
              chats={individualChats}
              refetchIndividualChats={refetchIndividualChats}
              refetchFavorites={refetchFavorites}
              cachedBranding={cachedBranding}
              setSelectedChat={setSelectedChat}
              refetchInstituteChats={refetchInstituteChats}
              refetchInstituteChatsGroup={refetchInstituteChatsGroup}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Individual;
