import React, { useState } from "react";
import { Link } from "react-router-dom";
import DeleteMaterialModal from "./DeleteMaterialModal";
import RenameFileModal from "./RenameFileModal";
import moment from "moment";
import DeleteQnaMaterialModal from "components/Qna/DeleteQnaMaterialModal";

function MaterialsTableRow({ file, refetch, cachedBranding, isQna }) {
  const [collapse, setCollapse] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);

  return (
    <tr className="align-middle">
      <th scope="row" className="table-details d-flex align-items-center">
        <Link to={isQna ? `/qna/qna-material-file/${file?.materialId}` : "#"}>
          <img
            className="mx-1 mb-2"
            style={{ width: "25px" }}
            src={`/images/txt.svg`}
          />
          <span
            className="fw-medium mx-1"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            {file?.materialFileName}
          </span>
        </Link>
      </th>
      <td className="fw-normal text-secondary" style={{ fontSize: "12px" }}>
        {file?.isUrlPdf ? "URL" : "PDF"}
      </td>
      <td className="fw-normal text-secondary" style={{ fontSize: "12px" }}>
        {file?.fileSize}
      </td>
      <td className="fw-normal text-secondary" style={{ fontSize: "12px" }}>
        {moment(file?.uploadedOn).format("MMM Do YY")}
      </td>
      <td className="">
        <div className="dropdown">
          <button
            className="px-0 bg-transparent border-0"
            onClick={() => setCollapse(!collapse)}
          >
            <img src="/images/more-chat.svg" style={{ width: "5px" }} />
          </button>
          <div
            className={`dropdown-menu ${collapse ? "show" : ""} user-dropdown`}
            aria-labelledby="dropdownMenu2"
          >
            {/* <button
              className="dropdown-item chat-dropdown"
              type="button"
              onClick={() => sestShowRenameModal(true)}
            >
              Rename{" "}
              <img src="/images/edit-user.svg" style={{ width: "25px" }} />
            </button> */}
            <button
              className="dropdown-item chat-dropdown"
              type="button"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete{" "}
              <img src="/images/delete-user.svg" style={{ width: "25px" }} />
            </button>
          </div>
        </div>
      </td>
      <DeleteQnaMaterialModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        file={file}
        refetch={refetch}
      />
      <RenameFileModal
        active={showRenameModal}
        handleClose={() => setShowRenameModal(false)}
        file={file}
        refetch={refetch}
      />
    </tr>
  );
}

export default MaterialsTableRow;
