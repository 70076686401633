import { Sidebar } from "components";
import { PageTitle, TabButton } from "components/Custom";
import { useEffect, useState } from "react";
import { useFetchFolders } from "hooks/materials";
import { useQueryClient } from "@tanstack/react-query";
import {
  useFetchAllCategories,
  useFetchChatCategorization,
  useFetchChatSettings,
} from "hooks/category";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import { useLocation } from "react-router-dom";
import ChatSettingsModal from "components/WalkThrough/ChatSettingsModal";
import toast from "react-hot-toast";
import {
  Category,
  InstituteSettings,
  InternetSettings,
  QASettings,
} from "components/ChatSettings";
import { useFetchActiveIndexers } from "hooks/azure";

function ChatSettings() {
  const location = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const [showQnA, setShowQnA] = useState(false);
  const [showMaterials, setShowMaterials] = useState(false);
  const [showInternetDataInChatConsole, setShowInternetDataInChatConsole] =
    useState(false);
  const [page, setPage] = useState(1);
  const [projectId, setProjectId] = useState();
  const [limit, setLimit] = useState(0);
  const [temperature, setTemperature] = useState(0);
  const [topP, setTopP] = useState(0);
  const [indexerId, setIndexerId] = useState();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [showChatSetting, setShowChatSetting] = useState(
    location?.state?.showModal || false
  );
  const [selected, setSelected] = useState("category");

  const { data: projects } = useFetchFolders();
  const { data: chatCategorization, refetch: refetchChatCategorization } =
    useFetchChatCategorization({ pageSize: 10, pageNumber: page });
  const { data: categories, refetch: refetchCategories } =
    useFetchAllCategories();
  const { data: chatSettings, refetch: refetchChatSettings } =
    useFetchChatSettings();
  const { data: activeIndexers } = useFetchActiveIndexers();

  useEffect(() => {
    if (chatSettings) {
      setIsChecked(chatSettings?.data[0]?.isAuto);
      setProjectId(
        chatSettings?.data[0]?.activeProjectId
          ? chatSettings?.data[0]?.activeProjectId
          : "00000000-0000-0000-0000-000000000000"
      );
      setIndexerId(
        chatSettings?.data[0]?.activeIndexerId
          ? chatSettings?.data[0]?.activeIndexerId
          : "00000000-0000-0000-0000-000000000000"
      );
      setShowInternetDataInChatConsole(chatSettings?.data[0]?.showInternet);
      setShowMaterials(chatSettings?.data[0]?.showMaterials);
      setShowQnA(chatSettings?.data[0]?.showQnA);
      setLimit(chatSettings?.data[0]?.maxTokenResponce);
      setTemperature(chatSettings?.data[0]?.temperature);
    }
  }, [chatSettings]);

  useEffect(() => {
    refetchChatCategorization();
  }, []);

  useEffect(() => {
    refetchChatCategorization({ pageNumber: page });
  }, [page]);

  const saveChatSettings = useMutation({
    mutationFn: (data) => {
      return axios.post(API_URL + `/Company/SaveChatSettings`, data, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: () => {
      refetchChatSettings();
      toast.success("Successfully saved");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSaveChatSettings = () => {
    saveChatSettings.mutate({
      folderId: projectId,
      autoCategorize: isChecked,
      activeIndexerId: indexerId,
      maxTokenResponce: limit,
      temperature: temperature,
      topP: topP,
      showQnA: showQnA,
      showMaterials: showMaterials,
      showInternet: showInternetDataInChatConsole,
    });
  };

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: cachedBranding?.page_BackgroundColor,
        minHeight: "100vh",
      }}
    >
      <div className="row flex-nowrap">
        <Sidebar
          handleTourClick={() => (
            setShowChatSetting(true), window.scrollTo(0, 0)
          )}
        />
        <div className="col-2 col-sm-3 col-md-2 col-lg-3 col-xl-2"></div>
        <div className="col-10 col-sm-9 col-md-10 col-lg-9 col-xl-10 px-sm-5">
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <PageTitle title="Chat Settings" time="29 May 2023 - 4:02 PM" />
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="user-option">
                    <nav className="nav nav-pills nav-fill res-opt-btns w-50">
                      <TabButton
                        active={"category" === selected ? true : false}
                        text="Category"
                        handleOnClick={() => setSelected("category")}
                      />
                      <TabButton
                        active={"internet-settings" === selected ? true : false}
                        text="Internet Settings"
                        handleOnClick={() => setSelected("internet-settings")}
                      />
                      <TabButton
                        active={
                          "institute-settings" === selected ? true : false
                        }
                        text="Institute Settings"
                        handleOnClick={() => setSelected("institute-settings")}
                      />
                      <TabButton
                        active={"qa-settings" === selected ? true : false}
                        text="Q&A Settings"
                        handleOnClick={() => setSelected("qa-settings")}
                      />
                    </nav>
                  </div>
                </div>
                <div className="col-12">
                  {"internet-settings" === selected ? (
                    <InternetSettings
                      cachedBranding={cachedBranding}
                      showInternetDataInChatConsole={
                        showInternetDataInChatConsole
                      }
                      setShowInternetDataInChatConsole={
                        setShowInternetDataInChatConsole
                      }
                    />
                  ) : "institute-settings" === selected ? (
                    <InstituteSettings
                      cachedBranding={cachedBranding}
                      limit={limit}
                      setLimit={setLimit}
                      temperature={temperature}
                      setTemperature={setTemperature}
                      topP={topP}
                      setTopP={setTopP}
                      activeIndexers={activeIndexers}
                      indexerId={indexerId}
                      setIndexerId={setIndexerId}
                      showMaterials={showMaterials}
                      setShowMaterials={setShowMaterials}
                    />
                  ) : "qa-settings" === selected ? (
                    <QASettings
                      cachedBranding={cachedBranding}
                      projectId={projectId}
                      setProjectId={setProjectId}
                      projects={projects}
                      showQnA={showQnA}
                      setShowQnA={setShowQnA}
                    />
                  ) : (
                    <Category
                      cachedBranding={cachedBranding}
                      isChecked={isChecked}
                      handleCheckboxChange={handleCheckboxChange}
                      chatCategorization={chatCategorization}
                      refetchChatCategorization={refetchChatCategorization}
                      page={page}
                      setPage={setPage}
                      categories={categories}
                      refetchCategories={refetchCategories}
                    />
                  )}
                </div>
                <div className="mt-3">
                  <button
                    type="button"
                    className="btn main-btn w-15 float-end"
                    onClick={handleSaveChatSettings}
                    style={{
                      backgroundColor: cachedBranding?.page_ButtonColor,
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatSettingsModal
        show={showChatSetting}
        handleClose={() => setShowChatSetting(false)}
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default ChatSettings;
