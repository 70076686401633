import React from "react";

function QASettings({
  cachedBranding,
  projectId,
  setProjectId,
  projects,
  showQnA,
  setShowQnA,
}) {
  return (
    <div>
      <div className="normal-card">
        <div className="px-3">
          <div className="switch-ai-g">
            <p
              className="no-margin"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Show Custom QnA in chat console
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={showQnA}
                onChange={() => setShowQnA(!showQnA)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="normal-card">
        <div className="px-3">
          <div className="chatset-title ">
            <h6
              style={{
                color: cachedBranding?.page_TextColor,
              }}
            >
              Chat Console
            </h6>
            <div className="py-2 ">
              <div className="color-pick w-100">
                <select
                  className="form-select border border-0"
                  style={{
                    fontSize: "12px",
                    color: cachedBranding?.page_TextColor,
                  }}
                  onChange={(e) => setProjectId(e.target.value)}
                  value={projectId}
                >
                  <option value="" disabled>
                    Select a project{" "}
                  </option>
                  {projects?.data.map((project, index) => (
                    <option
                      value={project?.folderId}
                      key={`${project.folderId}-${index}}`}
                    >
                      {project?.folderName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QASettings;
