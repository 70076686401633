import React, { useEffect, useState } from "react";
import EmptyChat from "./EmptyChat";
import Chat from "./Chat";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { cleanToken, generateUUID } from "utils";
import { API_URL } from "constants";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faMicrophone } from "@fortawesome/free-solid-svg-icons";

function ChatBox({
  chatType,
  chat,
  setChatType,
  setSelectedChat,
  selectedChat,
  refetchChat,
  group,
  groupInit,
  newGroup,
  chatSettings,
  isInstitute,
  isQna,
  cachedBranding,
  refetchFavorites,
  refetchIndividualChats,
  refetchInstituteChats,
  refetchInstituteChatsGroup,
  refetchMaterialChatsGroup,
  handleSelect,
  refetchGroups,
  selectedTab,
}) {
  const [chatText, setChatText] = useState("");
  const [tempText, setTempText] = useState("");
  const [newId, setNewId] = useState("");
  const [tempfields, setTempFields] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if ("new" === chatType) {
      setNewId(generateUUID());
    }
  }, [chatType]);

  useEffect(() => {
    if (newId) {
      setSelectedChat(newId);
    }
  }, [newId]);

  useEffect(() => {
    if ("new" === chatType && "group" === selectedTab && isEmpty(groupInit)) {
      setIsDisabled(true);
      setChatText("");
    } else {
      setIsDisabled(false);
    }
  }, [selectedTab, groupInit, chatType]);

  const sendMessage = useMutation({
    mutationFn: (fields) => {
      setTempFields(fields);
      return axios.post(API_URL + `/Chat/ChatGPT`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async () => {
      if ("new" === chatType) {
        setChatType("old");
        await setSelectedChat(newId);
      }
      refetchChat();

      setChatText("");
      setTempText("");
      refetchIndividualChats();
      refetchGroups();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const regenarateMessage = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/Chat/ChatGPT`,
        {
          chatId:
            "new" === chatType ? newId : !isEmpty(group) ? group : selectedChat,
          chatType: 0,
          isInternet: true,
          oldPrompt:
            "new" === chatType
              ? "0"
              : chat?.data?.cd[chat?.data?.cd.length - 1]?.text,
          prompt: chat?.data?.cd[chat?.data?.cd.length - 2]?.text,
        },
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setTempFields(tempfields);
      if ("new" === chatType) {
        setChatType("old");
        setSelectedChat(newId);
      }
      refetchChat();

      setChatText("");
      setTempText("");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const chatWithData = useMutation({
    mutationFn: (text) => {
      setTempFields(text);
      return axios.post(
        group.length === 0
          ? API_URL +
              `/QNA/ChatWithData/${
                "new" === chatType
                  ? "00000000-0000-0000-0000-000000000000"
                  : selectedChat
              }/${chatSettings?.activeProjectId}/${text}`
          : API_URL +
              `/QNA/ChatWithData/${
                "new" === chatType
                  ? "00000000-0000-0000-0000-000000000000"
                  : selectedChat
              }/${chatSettings?.activeProjectId}/${text}?GroupId=${groupInit}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setChatText("");
      setTempText("");
      if ("new" === chatType) {
        setChatType("old");
        setSelectedChat(data.data.newID);
        refetchChat();
      }
      refetchChat();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const chatWithDataInstitute = useMutation({
    mutationFn: (text) => {
      setTempFields(text);
      return axios.post(
        group.length === 0
          ? API_URL +
              `/Azure/ChatWithData/${
                "new" === chatType
                  ? "00000000-0000-0000-0000-000000000000"
                  : selectedChat
              }/${chatSettings?.activeIndexerId}/${text}`
          : API_URL +
              `/Azure/ChatWithData/${
                "new" === chatType
                  ? "00000000-0000-0000-0000-000000000000"
                  : selectedChat
              }/${chatSettings?.activeIndexerId}/${text}?GroupId=${groupInit}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      console.log(data);
      setChatText("");
      setTempText("");
      if ("new" === chatType) {
        console.log("dataaaaa: ", data?.data);
        setChatType("old");
        setSelectedChat(data.data.newID);
        refetchChat();
      }
      refetchChat();
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
    setTempText(chatText);
    setChatText("");
    if (!isEmpty(chatText)) {
      if (isInstitute) {
        chatWithDataInstitute.mutate(chatText);
      } else if (isQna) {
        chatWithData.mutate(chatText);
      } else {
        if (!isEmpty(group)) {
          sendMessage.mutate({
            chatId:
              "new" === chatType
                ? newId
                : !isEmpty(group)
                ? group
                : selectedChat,
            chatType: 0,
            isInternet: true,
            groupId: groupInit,
            oldPrompt:
              "new" === chatType
                ? "0"
                : chat?.data?.cd[chat?.data?.cd.length - 1]?.text,
            prompt: chatText,
          });
        } else {
          sendMessage.mutate({
            chatId:
              "new" === chatType
                ? newId
                : !isEmpty(group)
                ? group
                : selectedChat,
            chatType: 0,
            isInternet: true,
            oldPrompt:
              "new" === chatType
                ? "0"
                : chat?.data?.cd[chat?.data?.cd.length - 1]?.text,
            prompt: chatText,
          });
        }
      }
    }
  };

  const handleSubmitSuggested = (message) => {
    setChatType("old");

    if (!isEmpty(group)) {
      sendMessage.mutate({
        chatId: "new" === chatType ? newId : selectedChat,
        chatType: 0,
        isInternet: true,
        groupId: groupInit,
        oldPrompt:
          "new" === chatType
            ? "0"
            : chat?.data?.cd[chat?.data?.cd.length - 1]?.text,
        prompt: message,
      });
    } else {
      sendMessage.mutate({
        chatId: "new" === chatType ? newId : selectedChat,
        chatType: 0,
        isInternet: true,
        oldPrompt:
          "new" === chatType
            ? "0"
            : chat?.data?.cd[chat?.data?.cd.length - 1]?.text,
        prompt: message,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div
      className={`${
        "new" === chatType
          ? "col-7 col-md-7 col-lg-8 col-xl-9 chat-area"
          : "col-7 col-md-7 col-lg-8 col-xl-9 px-0 bg-white"
      }`}
    >
      <div className="chat-content">
        {"new" === chatType && isEmpty(tempText) ? (
          <EmptyChat
            handleSubmitSuggested={handleSubmitSuggested}
            cachedBranding={cachedBranding}
          />
        ) : (
          <Chat
            handleSelect={handleSelect}
            chat={chat?.data}
            refetchChat={refetchChat}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            tempText={tempText}
            refetchFavorites={refetchFavorites}
            refetchIndividualChats={refetchIndividualChats}
            refetchInstituteChats={refetchInstituteChats}
            refetchInstituteChatsGroup={refetchInstituteChatsGroup}
            refetchMaterialChatsGroup={refetchMaterialChatsGroup}
            cachedBranding={cachedBranding}
          />
        )}
        <div
          className="chat-footer"
          style={{ position: "new" === chatType ? "absolute" : "initial" }}
        >
          {"new" !== chatType && (
            <div className="regenarate">
              <button
                className="btn regenarate-btn"
                onClick={() => regenarateMessage.mutate()}
              >
                <img src="/images/regenerate.svg" />
                {"\u00A0"}
                {"\u00A0"}Regenerate Response
              </button>
            </div>
          )}
          <div className="chatbox-input rounded-pill mt-3">
            <button className="btn" type="file">
              <FontAwesomeIcon
                icon={faCirclePlus}
                fontSize={30}
                color={
                  !isEmpty(cachedBranding?.page_ButtonColor)
                    ? cachedBranding?.page_ButtonColor
                    : "#0844e1"
                }
              />
            </button>
            <input
              type="text"
              placeholder="Ask Anything!"
              value={chatText}
              onChange={(e) => setChatText(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={isDisabled}
            />
            <button
              className="btn send-btn"
              type="button"
              onClick={() => handleSubmit()}
              style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
            >
              Send <img src="/images/send.svg" />
            </button>
            <button className="btn mic mx-2" type="button">
              <FontAwesomeIcon
                icon={faMicrophone}
                fontSize={25}
                color={
                  !isEmpty(cachedBranding?.page_ButtonColor)
                    ? cachedBranding?.page_ButtonColor
                    : "#0844e1"
                }
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatBox;
