import React from "react";
import { Link } from "react-router-dom";

function MaterialFolder({ folder, icon, cachedBranding }) {
  return (
    <Link to={`/qna/qna-material-folder/${folder?.folderId}`}>
      <div className="folder">
        <img src={icon} />
      </div>
      <span style={{ color: cachedBranding?.page_TextColor, fontSize: "14px" }}>
        {folder?.folderName}
      </span>
    </Link>
  );
}

export default MaterialFolder;
