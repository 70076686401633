import React from "react";
import { Modal } from "react-bootstrap";

function NewFolderModal({
  active,
  handleClose,
  handleSave,
  folderName,
  setFolderName,
  cachedBranding,
}) {
  return (
    <Modal show={active} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
            New Project
          </h5>
          <div className="modal-option">
            <button
              type="button"
              className="btn"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="form-group profile-field w-100">
            <label>Project Name</label>
            <input
              type="text"
              className="form-control profile-input"
              placeholder="Enter Project Name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
            <small
              style={{
                color: cachedBranding?.page_TextColor,
                fontSize: "12px",
              }}
            >
              Project Name Shouldn't contain spaces
            </small>
          </div>
        </div>
        <div className="modal-footer d-flex">
          <button
            type="button"
            className="btn main-btn w-100"
            onClick={handleSave}
            style={{ backgroundColor: cachedBranding?.page_ButtonColor }}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default NewFolderModal;
