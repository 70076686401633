import React, { useState, useEffect } from "react";
import MaterialFolder from "./MaterialFolder";
import NewFolderModal from "./NewFolderModal";
import { useFetchFolders } from "hooks/materials";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { cleanToken } from "utils";
import { API_URL } from "constants";
import toast from "react-hot-toast";
import SearchUser from "components/Users/SearchUser";

function MaterialsCard({ cachedBranding }) {
  const [showModal, setShowModal] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [folderName, setFolderName] = useState("");
  const { data: folders, refetch, isFetching } = useFetchFolders();

  const [filteredFolders, setfilteredFolders] = useState(folders?.data);

  useEffect(() => {
    if (searchText != "") {
      var filtered = folders?.data.filter((c) =>
        c.folderName.includes(searchText)
      );
      setfilteredFolders(filtered);
    } else {
      setfilteredFolders(folders?.data);
    }
  }, [searchText]);

  useEffect(() => {
    setfilteredFolders(folders?.data);
  }, [folders]);

  const addFolder = useMutation({
    mutationFn: (fields) => {
      return axios.post(
        API_URL + `/QNA/CreateProject/${fields}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setShowModal(false);
      setFolderName("");
      refetch();
      toast.success("Folder successfully added");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSave = () => {
    if (folderName.includes(" ")) {
      toast.error("Folder name should not contain spaces!");
    } else {
      addFolder.mutate(folderName);
    }
  };

  return (
    <div className="normal-card sub-token">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-75">
          <h6
            className="me-5 mb-0"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            Projects{" "}
          </h6>
          <div className="search-bar w-50">
            <img src="/images/search.svg" className="search-ic" />
            <SearchUser searchText={searchText} setSearchText={setSearchText} />
          </div>
        </div>
        <button
          className="btn"
          onClick={() => setShowModal(true)}
          style={{ color: cachedBranding?.page_TextColor }}
        >
          <img className="px-2" src="/images/new-folder.svg" /> New Project
        </button>
      </div>
      <hr />
      <div className="d-flex flex-wrap m-2">
        {filteredFolders &&
          filteredFolders.map((folder, index) => (
            <div className=" adivgn-items-center mx-3 my-2" key={index}>
              <MaterialFolder
                folder={folder}
                icon="/images/folder.svg"
                cachedBranding={cachedBranding}
              />
            </div>
          ))}
      </div>
      <NewFolderModal
        active={showModal}
        handleClose={() => setShowModal(false)}
        handleSave={handleSave}
        folderName={folderName}
        setFolderName={setFolderName}
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default MaterialsCard;
