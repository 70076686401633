import React, { useEffect, useRef, useState } from "react";
import ColorInput from "./ColorInput";
import BotView from "./BotView";
import { isEmpty, set } from "lodash";

function BotSettings({
  botName,
  setBotName,
  botColor,
  setBotColor,
  botChatColor,
  setBotChatColor,
  userChatColor,
  setUserChatColor,
  topBannerColor,
  setTopBannerColor,
  fontStyle,
  setFontStyle,
  backgroundColor,
  setBackgroundColor,
  pattern,
  setPattern,
  welcomeMessage,
  setWelcomeMessage,
  handleBotChange,
  bot,
  tempBot,
  buttonColor,
  setButtonColor,
  bubbleColor,
  setBubbleColor,
  botIconContentType,
}) {
  const [selectedIcon, setSelectedIcon] = useState();
  const inputFile = useRef(null);

  const convertImageToFile = async (icon) => {
    const imageUrl = process.env.PUBLIC_URL + icon;
    try {
      const response = await fetch(imageUrl);
      const imageBlob = await response.blob();
      const convertedImageFile = new File([imageBlob], "newFileName.svg", {
        type: "image/svg+xml",
      });
      handleBotChange(convertedImageFile);
    } catch (error) {
      console.error("Error fetching or converting the image:", error);
    }
  };

  return (
    <div className="row w-100">
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="normal-card">
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label fs-5 fw-semibold">Bot Name</label>
                <input
                  type="email"
                  className="form-control profile-input p-4 fw-medium"
                  placeholder="Enter Bot Name"
                  value={botName}
                  onChange={(e) => setBotName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="">
                <h6 className="fw-semibold fs-5">Choose Bot Icon</h6>
                <div className="d-flex flex-row">
                  {!isEmpty(bot) && (
                    <div className="form-check p-0 ">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id="btn-check"
                        autoComplete="off"
                      />
                      <label
                        className={`btn bot btn-outline-primary rounded-circle border-2 bg-transparent selected-bot`}
                      >
                        <img
                          src={`data:${botIconContentType};base64,${bot}`}
                          width={73}
                        />
                      </label>
                    </div>
                  )}
                  <div
                    className="form-check p-0 "
                    onClick={() => (
                      convertImageToFile("/images/default-bot.svg"),
                      setSelectedIcon(1)
                    )}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check"
                      autoComplete="off"
                    />
                    <label
                      className={`btn bot ${
                        1 === selectedIcon
                          ? "btn-outline-primary rounded-circle border-2 bg-transparent selected-bot"
                          : ""
                      }`}
                    >
                      <img src="/images/default-bot.svg" />
                    </label>
                  </div>
                  <div
                    className="form-check p-1"
                    onClick={() => (
                      convertImageToFile("/images/bot-2.svg"),
                      setSelectedIcon(2)
                    )}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check"
                      autoComplete="off"
                    />
                    <label
                      className={`btn bot ${
                        2 === selectedIcon
                          ? "btn-outline-primary rounded-circle border-2 bg-transparent selected-bot"
                          : ""
                      }`}
                    >
                      <img src="/images/bot-2.svg" />
                    </label>
                  </div>
                  <div
                    className="form-check p-1"
                    onClick={() => (
                      convertImageToFile("/images/bot-3.svg"),
                      setSelectedIcon(3)
                    )}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check"
                      autoComplete="off"
                    />
                    <label
                      className={`btn bot ${
                        3 === selectedIcon
                          ? "btn-outline-primary rounded-circle border-2 bg-transparent selected-bot"
                          : ""
                      }`}
                    >
                      <img src="/images/bot-3.svg" />
                    </label>
                  </div>
                  <div
                    className="form-check p-1"
                    onClick={() => (
                      convertImageToFile("/images/bot-4.svg"),
                      setSelectedIcon(4)
                    )}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check"
                      autoComplete="off"
                    />
                    <label
                      className={`btn bot ${
                        4 === selectedIcon
                          ? "btn-outline-primary rounded-circle border-2 bg-transparent selected-bot"
                          : ""
                      }`}
                    >
                      <img src="/images/bot-4.svg" />
                    </label>
                  </div>
                  <div
                    className="form-check p-1"
                    onClick={() => (
                      convertImageToFile("/images/bot-5.svg"),
                      setSelectedIcon(5)
                    )}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check"
                      autoComplete="off"
                    />
                    <label
                      className={`btn bot ${
                        5 === selectedIcon
                          ? "btn-outline-primary rounded-circle border-2 bg-transparent selected-bot"
                          : ""
                      }`}
                    >
                      <img src="/images/bot-5.svg" />
                    </label>
                  </div>
                  <div
                    className="form-check p-1"
                    onClick={() => (
                      convertImageToFile("/images/bot-6.svg"),
                      setSelectedIcon(6)
                    )}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check"
                      autoComplete="off"
                    />
                    <label
                      className={`btn bot ${
                        6 === selectedIcon
                          ? "btn-outline-primary rounded-circle border-2 bg-transparent selected-bot"
                          : ""
                      }`}
                    >
                      <img src="/images/bot-6.svg" />
                    </label>
                  </div>

                  <div
                    className="form-check p-1"
                    onClick={() => setSelectedIcon(7)}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check"
                      autoComplete="off"
                    />
                  </div>

                  <div
                    className="form-check p-1 mx-2 mt-3 mb-0"
                    onClick={() => {
                      inputFile.current.click();
                    }}
                  >
                    <label htmlFor="file-upload" className="custom-icon-upload">
                      <img src="/images/upload-ico.svg" />{" "}
                      <span className="fw-semibold text-black mx-1 text-decoration-none">
                        Upload icon
                      </span>
                    </label>
                  </div>
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    accept="image/*"
                    onChange={handleBotChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 px-1">
              <div className="d-flex mb-4">
                <ColorInput
                  label="Button Color"
                  className="w-50 pe-3"
                  value={buttonColor}
                  onChange={setButtonColor}
                />
                <ColorInput
                  label="Bot Chat Color"
                  className="w-50 px-3"
                  value={botChatColor}
                  onChange={setBotChatColor}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 px-1">
              <div className="d-flex w-100 mb-4">
                <ColorInput
                  label="User Chat Color"
                  className="px-3"
                  value={userChatColor}
                  onChange={setUserChatColor}
                />
                <ColorInput
                  label="Top Banner"
                  value={topBannerColor}
                  onChange={setTopBannerColor}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 px-1">
              <div className="d-flex w-100 mb-4">
                <div className="custom-color w-50">
                  <label
                    htmlFor="exampleColorInput"
                    className="form-label mb-2   fw-medium"
                  >
                    Font Style
                  </label>
                  <div className="color-pick w-100">
                    <select
                      className="form-select border border-0"
                      style={{ fontSize: "12px" }}
                      value={fontStyle || ""}
                      onChange={(e) => setFontStyle(e.target.value)}
                    >
                      <option value="">Select a font</option>
                      <option value={"Montserrat"}>Montserrat</option>
                      <option value={"Poppins"}>Poppins</option>
                      <option value={"Roboto"}>Roboto</option>
                      <option value={"Raleway"}>Raleway</option>
                    </select>
                  </div>
                </div>
                <ColorInput
                  label="Background Color"
                  className="px-2 mx-1 mb-4 w-50"
                  value={backgroundColor}
                  onChange={setBackgroundColor}
                />
              </div>
            </div>
            <div className="col-xl-6">
              <div className="d-flex mb-4">
                {/* <div className="custom-color w-50">
                  <label
                    htmlFor="exampleColorInput"
                    className="form-label mb-2   fw-medium"
                  >
                    Pattern
                  </label>
                  <div className="color-pick w-100">
                    <select
                      className="form-select border border-0"
                      style={{ fontSize: "12px" }}
                      value={pattern}
                      onChange={(e) => setPattern(e.target.value)}
                    >
                      <option value="no">No Pattern</option>
                      <option value="one">One</option>
                      <option value="two">Two</option>
                      <option value="three">Three</option>
                    </select>
                  </div>
                </div> */}
                <ColorInput
                  label="Bubble Color"
                  className="px-2 mx-1 mb-4 w-50"
                  value={bubbleColor}
                  onChange={setBubbleColor}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-4">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label fs-5 fw-semibold"
                >
                  Welcome Message
                </label>
                <input
                  type="text"
                  className="form-control profile-input p-4"
                  placeholder="Enter Welcome Message"
                  value={welcomeMessage}
                  onChange={(e) => setWelcomeMessage(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <BotView
        botName={botName}
        setBotName={setBotName}
        botColor={botColor}
        setBotColor={setBotColor}
        botChatColor={botChatColor}
        setBotChatColor={setBotChatColor}
        userChatColor={userChatColor}
        setUserChatColor={setUserChatColor}
        topBannerColor={topBannerColor}
        setTopBannerColor={setTopBannerColor}
        fontStyle={fontStyle}
        setFontStyle={setFontStyle}
        backgroundColor={backgroundColor}
        setBackgroundColor={setBackgroundColor}
        pattern={pattern}
        setPattern={setPattern}
        welcomeMessage={welcomeMessage}
        setWelcomeMessage={setWelcomeMessage}
        handleBotChange={handleBotChange}
        bot={bot}
        tempBot={tempBot}
        buttonColor={buttonColor}
        bubbleColor={bubbleColor}
        setBubbleColor={setBubbleColor}
        botIconContentType={botIconContentType}
      />
    </div>
  );
}

export default BotSettings;
