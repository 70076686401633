import { Sidebar } from "components";
import { PageTitle, RoundedButton, TabButton } from "components/Custom";
import {
  GroupRestriction,
  TokenBalanceCard,
  UsersRestriction,
} from "components/Settings";
import React, { useState, useRef, useEffect } from "react";
import { UploadCsv } from "components/Users";
import { YourPlan } from "components/WalkThrough";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import UploadCsvGroups from "components/Users/UploadCsvGroups";
import { useFetchCompanyUsers } from "hooks/users";
import { useFetchCompanyGroups } from "hooks/groups";

function TokenRestrictions() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const [selected, setSelected] = useState("token-restriction");
  const [showUploadCsv, setShowUploadCsv] = useState(false);
  const [showUploadGroupCsv, setShowUploadGroupCsv] = useState(false);
  const userRestrictionRef = useRef();
  const groupRestrictionRef = useRef();
  const [showModal, setShowModal] = useState(
    location?.state?.showModal || false
  );
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { data: users, refetch: refetchUsers } = useFetchCompanyUsers({
    pageSize: 10,
    pageNumber: page,
  });

  const { data: groups, refetch: refetchGroups } = useFetchCompanyGroups({
    pageSize: 8,
    pageNumber: page,
  });

  //users
  const useritemsPerPage = 10;
  const usertotalItems = users?.data?.totalCount;
  const usertotalPages = Math.ceil(usertotalItems / useritemsPerPage);

  //groups
  const groupitemsPerPage = groups?.data?.pageSize;
  const grouptotalItems = groups?.data?.totalCount;
  const grouptotalPages = Math.ceil(grouptotalItems / groupitemsPerPage);

  const handleSave = () => {
    // Call a function in the child component using the ref
    if ("users-restriction" === selected) {
      userRestrictionRef.current.handleUpdate({});
    } else {
      groupRestrictionRef.current.handleUpdate({});
    }
  };

  useEffect(() => {
    refetchUsers();
    refetchGroups();
  }, []);

  useEffect(() => {
    refetchUsers({ pageNumber: page }); // Refetch when the page changes
  }, [page]);

  useEffect(() => {
    refetchGroups({ pageNumber: page });
  }, [page]);
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: cachedBranding?.page_BackgroundColor,
        minHeight: "100vh",
      }}
    >
      <div className="row flex-nowrap">
        <Sidebar
          handleTourClick={() => (setShowModal(true), window.scrollTo(0, 0))}
        />
        <div className="col-2 col-sm-3 col-md-2 col-lg-3 col-xl-2"></div>
        <div className="col-10 col-sm-9 col-md-10 col-lg-9 col-xl-10 px-sm-5">
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <PageTitle
                  title="Token Restriction"
                  time="29 May 2023 - 4:02 PM"
                />
                {"token-restriction" !== selected ? (
                  <div className="col-5 col-lg-5 col-xl-4 filter">
                    <div className="add-user">
                      {"users-restriction" == selected ? (
                        <RoundedButton
                          text="Upload CSV"
                          image="/images/upload-csv.svg"
                          handleOnClick={() => setShowUploadCsv(true)}
                        />
                      ) : (
                        <RoundedButton
                          text="Upload Group CSV"
                          image="/images/upload-csv.svg"
                          handleOnClick={() => setShowUploadGroupCsv(true)}
                        />
                      )}

                      <RoundedButton
                        text="Save"
                        handleOnClick={handleSave}
                        className="btn btn-primary w-25"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-5 col-lg-5 col-xl-4 filter mb-4">
                    {" "}
                    <div className="add-user">
                      <br />
                    </div>{" "}
                  </div>
                )}
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                  <div className="user-option">
                    <nav className="nav nav-pills nav-fill res-opt-btns w-75">
                      <TabButton
                        active={"token-restriction" === selected ? true : false}
                        text="Token Restriction"
                        handleOnClick={() => setSelected("token-restriction")}
                      />
                      <TabButton
                        active={"users-restriction" === selected ? true : false}
                        text="Users Restriction"
                        handleOnClick={() => setSelected("users-restriction")}
                      />
                      <TabButton
                        active={"group-restriction" === selected ? true : false}
                        text="Group Restriction"
                        handleOnClick={() => setSelected("group-restriction")}
                      />
                    </nav>
                  </div>
                </div>
                <div className="col-12">
                  {"token-restriction" === selected ? (
                    <TokenBalanceCard cachedBranding={cachedBranding} />
                  ) : "users-restriction" === selected ? (
                    <UsersRestriction
                      ref={userRestrictionRef}
                      cachedBranding={cachedBranding}
                      users={users}
                      refetchUsers={refetchUsers}
                      usertotalPages={usertotalPages}
                      page={page}
                      setPage={setPage}
                    />
                  ) : (
                    <GroupRestriction
                      ref={groupRestrictionRef}
                      cachedBranding={cachedBranding}
                      groups={groups}
                      refetchGroups={refetchGroups}
                      grouptotalPages={grouptotalPages}
                      page={page}
                      setPage={setPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UploadCsv
        show={showUploadCsv}
        handleClose={() => setShowUploadCsv(false)}
        refetchUsers={refetchUsers}
      />
      <UploadCsvGroups
        show={showUploadGroupCsv}
        handleClose={() => setShowUploadGroupCsv(false)}
        refetchGroups={refetchGroups}
      />
      <YourPlan
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={() =>
          navigate("/settings/branding", { state: { showModal: true } })
        }
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default TokenRestrictions;
