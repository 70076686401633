import { isEmpty } from "lodash";
import React from "react";
import { generateUUID } from "utils";

function GroupRow({ chat, handleSelect, cachedBranding }) {
  const handleEmptyGuid = (guid) => {
    if ("00000000-0000-0000-0000-000000000000" === guid) {
      const id = generateUUID();
      handleSelect(id, "new", id, chat?.groupId);
    } else {
      handleSelect(chat?.chatId, "old");
    }
  };

  return (
    <div
      className="groups-card cursor-pointer"
      onClick={() => handleEmptyGuid(chat?.chatId)}
    >
      <img src="/images/group-chat.svg" />
      <div
        // className={`${
        //   chat?.missedMessages ? "group-info new" : "individual-info"
        // }`}
        className="individual-info"
      >
        <h6
          style={{
            color: cachedBranding?.page_TextColor,
          }}
        >
          {chat?.chatTitle}
        </h6>
        {/* <span>{chat?.lastMessage}
        </span> */}
      </div>
      {/* {chat?.missedMessages !== 0 && (
        <div className="new-count main-bg d-flex align-items-center justify-content-center text-center rounded-circle text-white p-2 fw-semibold">
          <span>{chat?.missedMessages}</span>
        </div>
      )} */}
    </div>
  );
}

export default GroupRow;
