import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { RoundedButton } from "components/Custom";
import { API_URL } from "constants";
import { useFetchFolders } from "hooks/materials";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { cleanToken } from "utils";

function NewScriptModal({ show, handleClose, refetch, cachedBranding }) {
  const { data: projects } = useFetchFolders();
  const [language, setLanguage] = useState("");
  const [project, setProject] = useState("");
  const [botName, setBotName] = useState("");
  const [type, setType] = useState("internet");

  const addScript = useMutation({

    mutationFn: () => {
      return axios.post(
        API_URL + `/api/BotScript/CreateBot`,
        {
          language: language,
          projectId: project,
          botName: botName,
          isProject: type == "internet" ? false : true,
        },
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      refetch();
      handleClose();
      toast.success("Successfully saved")
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleSubmit = () => {
   
      addScript.mutate();
    
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <h4
            className="modal-title"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            New Script
          </h4>
          <div className="modal-option">
            <button type="button" className="btn" onClick={handleClose}>
              <img src="/images/close-modal.svg" />
            </button>
          </div>
        </div>
        <div className="modal-body editProfile justify-content-around flex-column">
          <div className="row">
            <div className="w-50">
              <label
                className="script-label"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Bot Name
              </label>
              <input
                type="text"
                className="form-control profile-input"
                placeholder="Bot name"
                value={botName}
                onChange={(e) => setBotName(e.target.value)}
              />
            </div>
            <div className="w-50">
              <label
                className="script-label"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Language
              </label>
              <div className="color-pick">
                <select
                  className="form-select border border-0"
                  style={{ fontSize: "12px" }}
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value="HTML">HTML</option>
                  <option value="React">React</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="w-50">
              <label
                className="script-label"
                style={{ color: cachedBranding?.page_TextColor }}
              >
                Type
              </label>
              <div className="nav nav-tabs nav-fill data-filter px-1 mt-1">
                <a
                  className={`nav-item data-link cursor-pointer`}
                  onClick={() => setType("internet")}
                  style={{
                    backgroundColor:
                      "internet" === type
                        ? cachedBranding?.page_ButtonColor
                        : "",
                    color: "internet" === type ? "#fff" : "",
                  }}
                >
                  Internet Data
                </a>
                <a
                  className={`nav-item data-link cursor-pointer`}
                  onClick={() => setType("institute")}
                  style={{
                    backgroundColor:
                      "institute" === type
                        ? cachedBranding?.page_ButtonColor
                        : "",
                    color: "institute" === type ? "#fff" : "",
                  }}
                >
                  Institute Data
                </a>
              </div>
            </div>
            {"institute" === type && (
              <div className="w-50">
                <label
                  className="script-label"
                  style={{ color: cachedBranding?.page_TextColor }}
                >
                  Project
                </label>
                <div className="color-pick">
                  <select
                    className="form-select border border-0"
                    style={{ fontSize: "12px" }}
                    value={project}
                    onChange={(e) => setProject(e.target.value)}
                  >
                    {projects?.data.map((project, index) => (
                      <option
                        value={project?.folderId}
                        key={`${project.folderId}-${index}}`}
                      >
                        {project?.folderName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
          <div>
            <RoundedButton
              text="Done"
              handleOnClick={() => handleSubmit()}
              className="btn shadow-sm main-btn p-3 rounded-pill ms-3 float-end"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NewScriptModal;
