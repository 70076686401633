import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import UserRestrictionTableRow from "./UserRestrictionTableRow";
import UserRestrictionTableHeader from "./UserRestrictionTableHeader";
import { Pagination } from "components/Custom";
import { useFetchCompanyUsers } from "hooks/users";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import toast from "react-hot-toast";

const UsersRestriction = forwardRef(
  (
    { cachedBranding, users, page, setPage, usertotalPages, refetchUsers },
    ref
  ) => {
    const [usersData, setUsersData] = useState(users?.data?.items);
    const [changedRows, setChangedRows] = useState([]);

    useImperativeHandle(ref, () => ({
      handleUpdate,
    }));

    const editUser = useMutation({
      mutationFn: () => {
        return axios.put(
          API_URL + `/User/UpdateUserTokenBalance`,
          changedRows,
          {
            headers: {
              Authorization: `Bearer ${cleanToken(
                localStorage.getItem("token")
              )}`,
            },
          }
        );
      },
      onSuccess: async (data) => {
        refetchUsers();
        setChangedRows([]);
        toast.success("Saved Successfully");
      },
      onError: (error) => {
        setChangedRows([]);
        console.log("error: ", error);
      },
    });

    useEffect(() => {
      refetchUsers();
    }, []);

    useEffect(() => {
      setUsersData(users?.data?.items);
    }, [users]);

    useEffect(() => {
      refetchUsers({ pageNumber: page }); // Refetch when the page changes
    }, [page]);

    const handleUpdate = () => {
      editUser.mutate();
    };

    const handleUserUpdate = (updatedUser) => {
      // Extract only the desired fields
      const { userId, isActive, tokenBalance } = updatedUser;

      // Check if the updated user is already in the changedRows array
      const updatedRows = [...changedRows];
      const existingIndex = updatedRows.findIndex(
        (row) => row.userId === userId
      );
      if (existingIndex !== -1) {
        updatedRows[existingIndex] = {
          userId,
          isActive: isActive !== null ? isActive : true,
          tokenBalance: tokenBalance !== null ? tokenBalance : 0,
        };
      } else {
        updatedRows.push({
          userId,
          isActive: isActive !== null ? isActive : true,
          tokenBalance: tokenBalance !== null ? tokenBalance : 0,
        });
      }

      setChangedRows(updatedRows);
    };

    return (
      <>
        <div className="user-table">
          <div className="table-responsive-sm">
            <table className="table table-borderless tablecenter">
              <UserRestrictionTableHeader cachedBranding={cachedBranding} />
              <tbody>
                {users?.data?.items &&
                  users?.data?.items.map((user, index) => (
                    <UserRestrictionTableRow
                      user={user}
                      key={index}
                      onUpdate={(updatedUser) => handleUserUpdate(updatedUser)}
                      cachedBranding={cachedBranding}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination totalPages={usertotalPages} page={page} setPage={setPage} />
      </>
    );
  }
);

export default UsersRestriction;
