import React, { useEffect, useRef, useState } from "react";
import { Sidebar } from "components";
import {
  PageTitle,
  RoundOutlinedButton,
  RoundedButton,
  TabButton,
} from "components/Custom";
import {
  ChangeRoleModal,
  DeleteAccountModal,
  UsersTable,
  Groups,
  EditUserModal,
  UploadCsv,
} from "components/Users";
import { useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { AddGroup, AddUser } from "components/WalkThrough";
import { useExportUsersTable, useFetchCompanyUsers } from "hooks/users";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import { ConfirmDeleteModal } from "components/Admin/modal";
import UploadCsvGroups from "components/Users/UploadCsvGroups";
import { useExportGroup } from "hooks/groups";
import toast from "react-hot-toast";

function Users() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const cachedBranding = queryClient.getQueryData(["branding"])?.data;
  const { type } = location.state || "";
  const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [showCsvGroupModal, setShowCsvGroupModal] = useState(false);
  const [showAddUser, setShowAddUser] = useState(
    location?.state?.showModal || location?.state?.showAddUser || false
  );
  const [showAddGroup, setShowAddGroup] = useState(false);

  const [selected, setSelected] = useState("users");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedEditUser, setSelectedEditUser] = useState();
  const [editForm, setEditForm] = useState(false);
  const exportUsersTableQuery = useExportUsersTable();
  const exportGroupsQuery = useExportGroup();
  const [page, setPage] = useState(1);
  const { data: users, refetch: refetchUsers } = useFetchCompanyUsers({
    pageSize: 10,
    pageNumber: page,
  });
  const usersTableRef = useRef();
  const groupTableRef = useRef();
  useEffect(() => {
    refetchUsers({ pageNumber: page });
  }, [page]);

  const handleDownload = async () => {
    try {
      const data = await exportUsersTableQuery.mutateAsync();
      const url = window.URL.createObjectURL(data.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "users.xlsx";
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success("File  successfully installed");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDownloadGroups = async () => {
    try {
      const data = await exportGroupsQuery.mutateAsync();
      const url = window.URL.createObjectURL(data.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "groups.xlsx";
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success("File successfully installed");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const updateUserRole = useMutation({
    mutationFn: (fields) => {
      return axios.put(API_URL + `/Role/UpdateUserRole`, fields, {
        headers: {
          Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
        },
      });
    },
    onSuccess: async (data) => {
      setSelectedUsers([]);
      usersTableRef.current.handleRefetch({});
      toast.success("Role successfully updated");
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleRoleChange = async (selectedRoleId) => {
    try {
      updateUserRole.mutate(selectedRoleId);
    } catch (error) {
      console.error("Error updating user roles:", error);
    }
  };
  const handleQuickTourClick = () => {
    if (selected === "users") {
      setShowAddUser(true);
    } else if (selected === "groups") {
      setShowAddGroup(true);
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (type === "groups") {
      setSelected("groups");
    } else {
      setSelected("users");
    }
  }, [type]);

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: cachedBranding?.page_BackgroundColor,
        minHeight: "100vh",
      }}
    >
      <div className="row flex-nowrap">
        <Sidebar handleTourClick={handleQuickTourClick} />
        <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2"></div>
        <div className="col-10 col-sm-9 col-md-9 col-lg-9 col-xl-10 px-sm-5">
          <div className="dashboard">
            <div className="page-title">
              <div className="row w-100">
                <PageTitle title="Users" time="29 May 2023 - 4:02 PM" />
                <div className="col-5 col-lg-4 col-xl-5 filter">
                  <div className="add-user">
                    {"users" === selected ? (
                      <RoundedButton
                        text="Add User"
                        image="/images/add-user.svg"
                        handleOnClick={() => (
                          setShowEditModal(true), setEditForm(true)
                        )}
                        className="w-50 me-2"
                      />
                    ) : (
                      <RoundedButton
                        text="New Group"
                        image="/images/add-user.svg"
                        handleOnClick={() => navigate("/new-group")}
                        className="w-50 me-2"
                      />
                    )}
                    {"users" === selected ? (
                      <RoundedButton
                        text="Upload CSV"
                        image="/images/upload-csv.svg"
                        handleOnClick={() => setShowCsvModal(true)}
                        className="w-50"
                      />
                    ) : (
                      <RoundedButton
                        text="Upload CSV"
                        image="/images/upload-csv.svg"
                        handleOnClick={() => setShowCsvGroupModal(true)}
                        className="w-50"
                      />
                    )}
                  </div>
                </div>
                <div className="col-5">
                  <div className="user-option">
                    <nav className="nav nav-pills nav-fill user-opt-btns w-100">
                      <TabButton
                        active={"users" === selected ? true : false}
                        text="All Users"
                        handleOnClick={() => setSelected("users")}
                      />
                      <TabButton
                        active={"groups" === selected ? true : false}
                        text="Groups"
                        handleOnClick={() => setSelected("groups")}
                      />
                    </nav>
                  </div>
                </div>
                <div className="col-7 d-flex justify-content-end align-items-center">
                  {"users" === selected ? (
                    <div className="d-flex justify-content-end align-items-center">
                      {!isEmpty(selectedUsers) && (
                        <>
                          <RoundOutlinedButton
                            text="Delete Selected"
                            color={cachedBranding?.page_ButtonColor}
                            handleClick={() => setShowDeleteModal(true)}
                          />
                          <RoundOutlinedButton
                            text="Change Role Selected"
                            color="blue"
                            handleClick={() => setShowChangeRoleModal(true)}
                          />
                        </>
                      )}
                      <RoundOutlinedButton
                        text="Export Table"
                        color="blue"
                        handleClick={handleDownload}
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end align-items-center">
                      <RoundOutlinedButton
                        text="Export"
                        color="blue"
                        handleClick={handleDownloadGroups}
                      />
                    </div>
                  )}
                </div>
                {"users" === selected ? (
                  <UsersTable
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    ref={usersTableRef}
                    users={users}
                    page={page}
                    setPage={setPage}
                    refetchUsers={refetchUsers}
                    cachedBranding={cachedBranding}
                  />
                ) : (
                  <Groups cachedBranding={cachedBranding} ref={groupTableRef} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangeRoleModal
        show={showChangeRoleModal}
        handleClose={() => setShowChangeRoleModal(false)}
        selectedUsers={selectedUsers}
        handleRoleChange={handleRoleChange}
      />
      <DeleteAccountModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleSubmit={() => (
          setShowDeleteModal(false), setShowConfirmModal(true)
        )}
        cachedBranding={cachedBranding}
      />
      <ConfirmDeleteModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleSubmit={() => {}}
        setSelectedUsers={setSelectedUsers}
        selectedUsers={selectedUsers}
        refetchUsers={refetchUsers}
      />
      <EditUserModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        refetchUsers={() => usersTableRef.current.handleRefetch({})}
        editForm={editForm}
      />
      {"users" === selected ? (
        <UploadCsv
          show={showCsvModal}
          handleClose={() => (
            setShowCsvModal(false), usersTableRef.current.handleRefetch({})
          )}
          cachedBranding={cachedBranding}
        />
      ) : (
        <UploadCsvGroups
          show={showCsvGroupModal}
          handleClose={() => (
            setShowCsvGroupModal(false), groupTableRef.current.handleRefetch({})
          )}
        />
      )}
      <AddUser
        show={showAddUser}
        handleClose={() => setShowAddUser(false)}
        handleSubmit={() => (
          setSelected("groups"), setShowAddUser(false), setShowAddGroup(true)
        )}
        cachedBranding={cachedBranding}
      />

      <AddGroup
        show={showAddGroup}
        handleClose={() => setShowAddGroup(false)}
        handleSubmit={() =>
          navigate("/chat-console", { state: { showModal: true } })
        }
        cachedBranding={cachedBranding}
      />
    </div>
  );
}

export default Users;
