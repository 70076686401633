import React from "react";
import NewChatButton from "./NewChatButton";
import QnaCard from "./QnaCard";

function QnaList({
  active,
  handleSelect,
  chats,
  refetchIndividualChats,
  refetchFavorites,
  instituteChats,
  cachedBranding,
  setSelectedChat,
  refetchInstituteChats,
  refetchInstituteChatsGroup,
  materialChats,
  refetchMaterialChats,
}) {
  return (
    <div className={`tab-pane fade ${active ? "show active" : ""}`}>
      <NewChatButton
        cachedBranding={cachedBranding}
        handleSelect={handleSelect}
        isGroup={false}
        setShowNewGroupChat={() => {}}
      />
      <div>
        {materialChats?.data &&
          materialChats?.data.map((message, index) => (
            <QnaCard
              handleSelect={handleSelect}
              message={message}
              key={index}
              refetchIndividualChats={refetchIndividualChats}
              refetchFavorites={refetchFavorites}
              cachedBranding={cachedBranding}
              setSelectedChat={setSelectedChat}
              refetchInstituteChats={refetchInstituteChats}
              refetchInstituteChatsGroup={refetchInstituteChatsGroup}
              refetchMaterialChats={refetchMaterialChats}
            />
          ))}
      </div>
    </div>
  );
}

export default QnaList;
