import { TabButton } from "components/Custom";
import React, { useState } from "react";
import MaxResponse from "./MaxResponse";
import TemperatureTopP from "./TemperatureTopP";
import ComingSoon from "components/Custom/ComingSoon";
import { useFetchActiveIndexers } from "hooks/azure";

function InstituteSettings({
  cachedBranding,
  limit,
  setLimit,
  temperature,
  setTemperature,
  topP,
  setTopP,
  activeIndexers,
  indexerId,
  setIndexerId,
  showMaterials,
  setShowMaterials,
}) {
  const [selected, setSelected] = useState("max-response");
  const { data, refetch } = useFetchActiveIndexers();

  return (
    <div className="py-4">
      <div className="normal-card">
        <div className="px-3">
          <div className="switch-ai-g">
            <p
              className="no-margin"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              Show Institute Data in chat console
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={showMaterials}
                onChange={() => setShowMaterials(!showMaterials)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="normal-card">
        <div className="px-3">
          <div className="chatset-title ">
            <h6
              style={{
                color: cachedBranding?.page_TextColor,
              }}
            >
              Active Indexer
            </h6>
            <div className="py-2 ">
              <div className="color-pick w-100">
                <select
                  className="form-select border border-0"
                  style={{
                    fontSize: "12px",
                    color: cachedBranding?.page_TextColor,
                  }}
                  onChange={(e) => setIndexerId(e.target.value)}
                  value={indexerId}
                >
                  <option value="" disabled>
                    Select an Index{" "}
                  </option>
                  {data?.data.map(
                    (activeIndexer, index) =>
                      activeIndexer?.isIndexed && (
                        <option
                          value={activeIndexer?.containerId}
                          key={`${activeIndexer.containerId}-${index}}`}
                        >
                          {activeIndexer?.indexName}
                        </option>
                      )
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="nav nav-pills nav-fill res-opt-btns w-75">
        <TabButton
          active={"max-response" === selected ? true : false}
          text="Max Response"
          handleOnClick={() => setSelected("max-response")}
        />
        <TabButton
          active={"temp-top-p" === selected ? true : false}
          text="Temperature & Top P"
          handleOnClick={() => setSelected("temp-top-p")}
        />
        <TabButton
          active={"stop-sequence" === selected ? true : false}
          text="Stop Sequence"
          handleOnClick={() => setSelected("stop-sequence")}
        />
        <TabButton
          active={"frequency-presence" === selected ? true : false}
          text="Frequency & Presence"
          handleOnClick={() => setSelected("frequency-presence")}
        />
      </nav>
      {"max-response" === selected ? (
        <MaxResponse limit={limit} setLimit={setLimit} />
      ) : "stop-sequence" === selected ? (
        <ComingSoon />
      ) : "frequency-presence" === selected ? (
        <ComingSoon />
      ) : (
        <TemperatureTopP
          temperature={temperature}
          setTemperature={setTemperature}
          topP={topP}
          setTopP={setTopP}
        />
      )}
    </div>
  );
}

export default InstituteSettings;
