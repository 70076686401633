import React from "react";
import WarningMessage from "./WarningMessage";
import TableChatSettings from "./TableChatSettings";

function Category({
  cachedBranding,
  isChecked,
  handleCheckboxChange,
  chatCategorization,
  refetchChatCategorization,
  page,
  setPage,
  categories,
  refetchCategories,
}) {
  return (
    <div>
      <div className="normal-card">
        <div className="px-3">
          <div className="switch-ai-g">
            <p
              className="no-margin"
              style={{ color: cachedBranding?.page_TextColor }}
            >
              AI Generated Category
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
          {isChecked && <WarningMessage cachedBranding={cachedBranding} />}
        </div>
      </div>
      <div>
        {!isChecked && (
          <TableChatSettings
            chatCategorization={chatCategorization}
            refetchChatCategorization={refetchChatCategorization}
            page={page}
            setPage={setPage}
            refetchCategories={refetchCategories}
            categories={categories}
            cachedBranding={cachedBranding}
          />
        )}
      </div>
    </div>
  );
}

export default Category;
