import React, { useEffect, useState } from "react";
import DeleteChatModal from "./DeleteChatModal";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { cleanToken } from "utils";
import BotMessage from "./BotMessage";
import moment from "moment";

function Chat({
  chat,
  refetchChat,
  selectedChat,
  tempText,
  refetchFavorites,
  refetchIndividualChats,
  refetchInstituteChats,
  refetchInstituteChatsGroup,
  refetchMaterialChatsGroup,
  setSelectedChat,
  handleSelect,
  cachedBranding,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(chat?.chatTitle);
  }, [chat]);

  const editTitle = useMutation({
    mutationFn: () => {
      return axios.post(
        API_URL + `/Chat/EditChatTitleById/${selectedChat}/${title}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cleanToken(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
    },
    onSuccess: async () => {
      refetchChat();
      refetchFavorites();
      refetchIndividualChats();
      refetchInstituteChats();
      refetchInstituteChatsGroup();
      refetchMaterialChatsGroup();
      setEdit(!edit);
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const handleEditTitle = () => {
    if (edit) {
      editTitle.mutate();
    } else {
      setEdit(!edit);
    }
  };

  return (
    <>
      <div
        className="chat-header"
        style={{ backgroundColor: cachedBranding?.topBannerColor }}
      >
        {!edit ? (
          <h6
            className="fw-medium m-0"
            style={{ color: cachedBranding?.page_TextColor }}
          >
            {chat?.chatTitle}
          </h6>
        ) : (
          <input
            className="fw-medium m-0 w-100"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        )}
        <div className="chat-actions d-flex">
          <button className="btn" onClick={() => handleEditTitle()}>
            {!edit ? (
              <img src="/images/edit-user.svg" />
            ) : (
              <img src="/images/save.svg" />
            )}
          </button>
          <button className="btn" onClick={() => setShowDeleteModal(true)}>
            <img src="/images/delete-user.svg" />
          </button>
        </div>
      </div>
      <div
        className="chat-body px-5"
        style={{ backgroundColor: cachedBranding?.botBackgroundColor }}
      >
        {chat?.cd &&
          chat?.cd.map((message, index) =>
            !message?.isUserNotBot ? (
              <>
                <BotMessage
                  message={message}
                  refetchChat={refetchChat}
                  key={index}
                  cachedBranding={cachedBranding}
                />
                <small className="time">
                  {moment(new Date(message?.dateCreated)).isSame(
                    moment(),
                    "day"
                  ) ? (
                    <>
                      {moment(new Date(message?.dateCreated)).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      {moment(new Date(message?.dateCreated)).format("hh:mm A")}
                      <br />
                      <span>
                        {moment(new Date(message?.dateCreated)).format(
                          "MMM D, YY"
                        )}
                      </span>
                    </>
                  )}
                </small>
              </>
            ) : (
              <>
                <div className="guest-bubble" key={index}>
                  <p
                    className="mb-0"
                    style={{
                      backgroundColor: cachedBranding?.bubbleColor,
                      color: cachedBranding?.userChatColor,
                      fontFamily: `${cachedBranding?.fontStyle}, sans-serif`,
                    }}
                  >
                    {message?.text}
                  </p>
                </div>
                <small className="align-self-end time">
                  {moment(new Date(message?.dateCreated)).isSame(
                    moment(),
                    "day"
                  ) ? (
                    <>
                      {moment(new Date(message?.dateCreated)).format("hh:mm A")}
                    </>
                  ) : (
                    <>
                      {moment(new Date(message?.dateCreated)).format("hh:mm A")}
                      <br />
                      <span>
                        {moment(new Date(message?.dateCreated)).format(
                          "MMM D, YY"
                        )}
                      </span>
                    </>
                  )}
                </small>
              </>
            )
          )}
        {tempText && (
          <div className="guest-bubble">
            <p>{tempText}</p>
          </div>
        )}
      </div>
      <DeleteChatModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        chat={chat}
        setSelectedChat={setSelectedChat}
        refetchFavorites={refetchFavorites}
        refetchIndividualChats={refetchIndividualChats}
        refetchInstituteChats={refetchInstituteChats}
        refetchInstituteChatsGroup={refetchInstituteChatsGroup}
        refetchMaterialChatsGroup={refetchMaterialChatsGroup}
        handleSelect={handleSelect}
      />
    </>
  );
}

export default Chat;
